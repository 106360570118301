import moment from 'moment';
import { formatSecondsToTime } from './formatters';

const helpers = {
  isEmbedded: media => {
    return media.type !== 'survey' && media.document_type === 'embedded';
  },

  isSurvey: media => {
    return media.type === 'survey' || media.type === 'post_survey';
  },

  isForm: media => {
    return media.type === 'form';
  },

  getToolTypeLabel: media => {
    switch (media.type) {
      case 'lesson':
        return 'Guide';
      case 'guide':
        return 'Handout';
      case 'sample':
        return 'Sample';
      case 'worksheet':
        return 'Worksheet';
      case 'video':
        return 'Video';
      case 'faqs':
        return 'FAQs';
      case 'survey':
        return 'Survey';
      default:
        return '';
    }
  },

  getPreSurvey: practice => {
    const { phases } = practice;
    const surveyResources = [];
    let questions = [];

    phases.forEach(phase => {
      const { resources } = phase;

      resources && resources.forEach(resource => {
        if (resource.media.survey_type === 'pre') {
          surveyResources.push(resource);
        }
      });
    });

    if (surveyResources.length) {
      surveyResources.forEach(question => {
        const surveyQuestions = question.media.survey_questions;

        if (Array.isArray(surveyQuestions)) {
          questions = questions.concat(surveyQuestions.map(q => ({
            id: q.id + '_post',
            question: q.question,
            answer_type: q.type.answer_type,
            choices: q.type.choices,
            selections: q.type.selections,
            includeOther: q.type.include_other,
            required: q.type.required,
            conditional: q.conditional,
            answer: q.type.answer_type === 'dropdown' && q.type.selections && q.type.selections.length ? q.type.selections[0].text : '',
            type: 'post'
          })));
        }
      });
    }

    return questions;
  },

  getFirstStartDate: practices => {
    let timestamp = moment();

    practices.forEach(p => {
      p.statuses.forEach(s => {
        if (s.status === 'in_progress' && moment(s.timestamp) < timestamp) timestamp = moment(s.timestamp);
      });
    });

    return timestamp.format('MMMM D, YYYY');
  },

  getCompletedOnDate: practice => {
    const timestamp = practice.completed_on ? moment(practice.completed_on) : moment();
    return timestamp.format('MMMM D, YYYY');
  },

  getLatestStatus: statuses => {
    let status = 'pending';
    let timestamp = moment('September 16, 1985');

    statuses.forEach(s => {
      const ts = moment(s.timestamp);
      if (ts > timestamp) {
        status = s.status;
        timestamp = ts;
      }
    });

    return status;
  },

  getCurrentPhase: practice => {
    let phase = '';

    practice.phases.forEach(p => {
      if (p.status === 'in_progress') phase = p.title;
    });

    return phase;
  },

  getGettingsStartedSteps: practices => {
    const gettingsStartedSteps = practices.filter(p => p.details.getting_started_step);
    if (gettingsStartedSteps.length) return gettingsStartedSteps;
    return false;
  },

  percentComplete: (data, type) => {
    let total = 0;
    let complete = 0;

    if (type === 'phase' && data.resources && data.section_type === 'phase') {
      data.resources.forEach(r => {
        total++;
        if (r.status === 'complete') complete++;
      });
    } else if (data.phases) {
      data.phases.forEach(p => {
        if (p.resources && p.section_type === 'phase') {
          p.resources.forEach(r => {
            total++;
            if (r.status === 'complete') complete++;
          });
        }
      });
    }

    if (total) return Math.floor((complete / total) * 100);
    return 0;
  },

  completionTime: (data, type) => {
    let time = 0;

    if (type === 'phase' && data.resources) {
      data.resources.forEach(r => {
        if (r.status !== 'complete') {
          time += helpers.getSeconds(r.details.duration);
        }
      });
    } else if (data.phases) {
      data.phases.forEach(p => {
        if (p.resources) {
          p.resources.forEach(r => {
            if (r.status !== 'complete') {
              time += helpers.getSeconds(r.details.duration);
            }
          });
        }
      });
    }

    return formatSecondsToTime(time);
  },

  getSeconds: duration => {
    const value = parseInt(duration.value);

    switch (duration.time_span) {
      case 'minutes':
        return value * 60;
      case 'hours':
        return value * 60 * 60;
      case 'days':
        return value * 60 * 60 * 24;
      case 'weeks':
        return value * 60 * 60 * 24 * 7;
      default:
        return value * 60 * 60 * 24 * 7 * 4;
    }
  },

  shouldShowConditionalSurveyQuestion: (quest, questions) => {
    if (!quest.conditional || !quest.conditional.conditional) return true;
    const { question_id, value } = quest.conditional;
    const question = questions.find(q => q.id === question_id);
    const answer = question ? question.answer.toString() : false;
    return answer === value;
  },

  sortBy: (array, key, direction = 'asc') => {
    if (Array.isArray(array)) {
      array.sort((a, b) => {
        const itemA = a[key].toLowerCase();
        const itemB = b[key].toLowerCase();
        let compare = 0;

        if (itemA > itemB) {
          compare = direction === 'asc' ? 1 : -1;
        } else if (itemA < itemB) {
          compare = direction === 'asc' ? -1 : 1;
        }

        return compare;
      });
    }
    return array;
  }
};

const {
  isEmbedded,
  isSurvey,
  isForm,
  getToolTypeLabel,
  getPreSurvey,
  getFirstStartDate,
  getCompletedOnDate,
  getLatestStatus,
  getCurrentPhase,
  getGettingsStartedSteps,
  percentComplete,
  completionTime,
  shouldShowConditionalSurveyQuestion,
  sortBy
} = helpers;

export {
  helpers,
  isEmbedded,
  isSurvey,
  isForm,
  getToolTypeLabel,
  getPreSurvey,
  getFirstStartDate,
  getCompletedOnDate,
  getLatestStatus,
  getCurrentPhase,
  getGettingsStartedSteps,
  percentComplete,
  completionTime,
  shouldShowConditionalSurveyQuestion,
  sortBy
};
