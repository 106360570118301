import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const LogoUploader = ({ className, logo, onAddLogo, onRemoveLogo }) => {

  const handleAddLogo = files => {
    if (files && files.length) {
      const file = files[0];
      const fileReader = new FileReader();

      fileReader.onload = fileLoadedEvent => {
        var srcData = fileLoadedEvent.target.result;
        onAddLogo(srcData)
      }

      fileReader.readAsDataURL(file);
    }
  };

  const getField = () => {
    if (logo) {
      return (
        <>
          <div
            className="logo_preview"
            style={{ backgroundImage: `url(${logo})` }}
          >
            <i className="remover" onClick={onRemoveLogo} />
          </div>
        </>
      );
    }

    return <input type="file" onChange={e => handleAddLogo(e.target.files)} />;
  };

  return (
    <div className={`${className} logo_uploader`}>
      {getField()}
    </div>
  );
};

const StyledLogoUploader = styled(LogoUploader)`
  margin-bottom: 20px;

  .logo_preview {
    position: relative;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;

    .remover {
      position: absolute;
      top: 10px;
      right: -20px;
      width: 15px;
      height: 15px;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        transform-origin: center;
        height: 2px;
        background-color: ${styles.primary};
        transition: background-color 200ms;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover {
        &::before,
        &::after {
          background-color: ${styles.secondary};
        }
      }
    }
  }

  input {
    font-size: 14px;
  }
`;

export { StyledLogoUploader as LogoUploader };
