import React from 'react';

import {
  Header,
  PageTabs,
  AccountOrganizationForm
} from '../components';

const pageTabs = [
  { url: '/account', label: 'Manage Account' },
  { url: '/account/organization', label: 'Manage Organization' },
  { url: '/account/password', label: 'Update Password' }
];


export const AccountManageOrganization = () => (
  <div>
    <Header title="My Organization" />
    <PageTabs tabs={pageTabs} />
    <AccountOrganizationForm />
  </div>
);
