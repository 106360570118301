import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const StatusBadge = ({ className, status, size = ''}) => (
  <i className={['status_badge', className, status, size].join(' ')} />
);


const StyledStatusBadge = styled(StatusBadge)`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid ${styles.border};
  border-radius: 50%;

  &.small {
    width: 18px;
    height: 18px;
  }

  &.in_progress {

  }

  &.complete {
    background-color: ${styles.primary};
    border-color: ${styles.primary};

    &::before {
      position: absolute;
      top: 8px;
      left: 6px;
      transform-origin: center;
      transform: rotate(-40deg);
      content: '';
      height: 8px;
      width: 17px;
      border: solid #fff;
      border-width: 0 0 2px 2px;
    }

    &.small {
      &::before {
        top: 4px;
        left: 2px;
        height: 5px;
        width: 11px;
        border-width: 0 0 2px 2px;
      }
    }
  }

  &.in_progress {
    background-color: ${styles.secondary};
    border-color: ${styles.secondary};

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 10px;
      width: 0;
      height: 0;
      border: 12px solid;
      border-color: transparent transparent transparent #fff;
      transform: scaleY(0.65);
    }

    &.small {
      &::before {
        top: 0;
        left: 5px;
        border: 8px solid;
        border-color: transparent transparent transparent #fff;
        transform: scaleY(0.55);
      }
    }
  }
`;

export { StyledStatusBadge as StatusBadge };
