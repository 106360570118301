import React from 'react';
import ReactDOM from 'react-dom';
import { App, ContextProvider } from './components';
import TagManager from 'react-gtm-module';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID
});

const Root = () => {
  return (
    <ContextProvider>
      <App />
    </ContextProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
