import React from 'react';
import styled from 'styled-components';

class BestPracticesPageDocument extends React.Component {
  state = {
    showOverlay: false,
    showDocument: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showOverlay: true });
    }, 50);
    setTimeout(() => {
      this.setState({ showDocument: true });
    }, 250);
  }

  handleHideOverlay = () => {
    const { handleCloseDocument } = this.props;

    this.setState({ showOverlay: false, showDocument: false }, () => {
      setTimeout(handleCloseDocument, 300);
    });
  };

  render() {
    const { className, documentUrl } = this.props;
    const { showOverlay, showDocument } = this.state;

    return (
      <div
        className={`${className} ${showOverlay ? 'show' : ''}`}
        onClick={this.handleHideOverlay}
      >
      {showDocument && (
        <div className="document_modal">
          <i className="closer" onClick={this.handleHideOverlay} />
          <iframe src={documentUrl} title={documentUrl} />
        </div>
      )}
      </div>
    );
  }
}

const StyledBestPracticesPageDocument = styled(BestPracticesPageDocument)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 20000;
  opacity: 0;
  transition: opacity 300ms;

  &.show {
    opacity: 1;
  }

  .document_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
    padding: 20px 40px;

    iframe {
      width: 100%;
      border: 0;
      height: calc(100vh - 40px);

      &:focus {
        outline: none;
      }
    }

    .closer {
      top: 0;
      right: 0;
      transition: opacity 50ms;
      cursor: pointer;

      &::before,
      &::after {
        background-color: #fff;
      }

      &:hover {
        opacity: 0.8;
        transition: opacity 300ms;
      }
    }
  }
`;

export { StyledBestPracticesPageDocument as BestPracticesPageDocument };
