const format = {
  pluralize: (value, timeSpan) => {
    const span = value === '1' || value === 1 ? timeSpan.slice(0, -1) : timeSpan;
    return `${value} ${span}`;
  },

  formatSecondsToTime: time => {
    let seconds = time;
    let timeString = '';

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    // const month = week * 4;

    // const months = Math.floor(seconds / month);
    // seconds -= months * month;
    const weeks = Math.floor(seconds / week);
    seconds -= weeks * week;
    const days = Math.floor(seconds / day);
    seconds -= days * day;
    const hours = Math.floor(seconds / hour);
    seconds -= hours * hour;
    const minutes = Math.floor(seconds / minute);
    seconds -= minutes * minute;

    // if (months) timeString += `${format.pluralize(months, 'months')} `;
    if (weeks) timeString += `${format.pluralize(weeks, 'weeks')} `;
    if (days) timeString += `${format.pluralize(days, 'days')} `;
    if (hours) timeString += `${format.pluralize(hours, 'hours')} `;
    if (minutes) timeString += `${format.pluralize(minutes, 'minutes')} `;

    return timeString.trim();
  }
};

const {
  pluralize,
  formatSecondsToTime
} = format;

export {
  format,
  pluralize,
  formatSecondsToTime
};
