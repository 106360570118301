import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Button, BestPracticePageMediaButton } from '../components';

import { isSurvey, isForm } from '../../libs';


const BestPracticePageActionButtons = props => {
  const {
    className,
    status,
    media,
    resourceId,
    handleStartStep,
    handleCompleteStep,
    handleCompleteSurvey,
    handleMarkAsInProgress,
    handleShowVideo,
    handleShowDocument,
    handleToggleEmbed,
    handleShowFeedbackModal,
    embedIsOpen,
    surveyIsComplete
  } = props;

  const getButtons = () => {
    switch (status) {
      case 'in_progress':
        // Survey/Form Questions //
        if (isSurvey(media) || isForm(media)) {
          const type = isSurvey(media) ? 'Survey' : 'Form';
          return (
            <Fragment>
              {/* getMediaButton('secondary outline') */}
              <BestPracticePageMediaButton
                buttonStyle="secondary outline"
                media={media}
                handleShowVideo={handleShowVideo}
                handleShowDocument={handleShowDocument}
                handleToggleEmbed={handleToggleEmbed}
                embedIsOpen={embedIsOpen}
              />
              <i className="pipe" />
              <Button handleClick={() => handleCompleteSurvey(resourceId)} disabled={!surveyIsComplete}>
                <i className="checkmark" />
                Complete {type}
              </Button>
            </Fragment>
          );
        }

        // Non-Survey/Form //
        return (
          <Fragment>
            {/* getMediaButton('secondary outline') */}
            <BestPracticePageMediaButton
              buttonStyle="secondary outline"
              media={media}
              handleShowVideo={handleShowVideo}
              handleShowDocument={handleShowDocument}
              handleToggleEmbed={handleToggleEmbed}
              embedIsOpen={embedIsOpen}
            />
            <i className="pipe" />
            <Button handleClick={handleCompleteStep}>
              <i className="checkmark" />
              Mark As Complete
            </Button>
          </Fragment>
        );
      case 'complete':
        // Survey/Form Questions //
        if (isSurvey(media) || isForm(media)) {
          const type = isSurvey(media) ? 'survey' : 'form';
          return (<p className="survey_complete_text">{`This ${type} has been completed.`}</p>);
        }

        // Non-Survey/Form //
        return (
          <Fragment>
            {/* getMediaButton('primary') */}
            <BestPracticePageMediaButton
              buttonStyle="primary"
              media={media}
              handleShowVideo={handleShowVideo}
              handleShowDocument={handleShowDocument}
              handleToggleEmbed={handleToggleEmbed}
              embedIsOpen={embedIsOpen}
            />
            <i className="pipe" />
            <Button handleClick={handleMarkAsInProgress} buttonStyle="secondary outline">
              <i className="fa fa-pencil" aria-hidden="true"></i>
              Mark As In Progress
            </Button>
          </Fragment>
        )
      default:
        return <Button handleClick={handleStartStep}>Begin Step</Button>;
    }
  };

  return (
    <span className={className}>
      {getButtons()}
      {/* removing check for now */}
      {(true || (status && status !== 'pending')) && (
        <Button
          handleClick={handleShowFeedbackModal}
          buttonStyle="secondary feedback"
          tooltip="Feedback"
        >
          <i className="fa fa-commenting-o" aria-hidden="true"></i>
        </Button>
      )}
    </span>
  );
};

const StyledBestPracticePageActionButtons = styled(BestPracticePageActionButtons)`
  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .feedback {
    float: right;
    text-align: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    text-align: center;

    .fa {
      font-size: 18px;
    }
  }

  .pipe {
    height: 38px;
    margin: 0 20px;
    vertical-align: middle;
    transform: translateY(0);
    opacity: 0.8;
  }

  .checkmark {
    display: inline-block;
    width: 20px;

    &::before {
      content: '';
      display: block;
      position: relative;
      height: 6px;
      width: 12px;
      border: solid #fff;
      border-width: 0 0 2px 2px;
      transform-origin: center;
      transform: rotate(-40deg) translate(2px, -3px);
    }
  }

  .survey_complete_text {
    font-family: ${styles.nationalBold};
    margin-bottom: 5px !important;
  }
`;

export { StyledBestPracticePageActionButtons as BestPracticePageActionButtons };
