import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

const BestPracticePageSupplementalReading = ({ data = {}, className }) => {
  const { title, subtitle, content } = data;

  return (
    <div className={className} style={{ margin: '40px 0 10px' }}>
      {title && <h3>{title}</h3>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {content && <Content dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};

const StyledBestPracticePageSupplementalReading = styled(BestPracticePageSupplementalReading)`
  background-color: ${styles.primary}10;
  padding: 35px 40px 20px;
  border-radius: 5px;
`;

export { StyledBestPracticePageSupplementalReading as BestPracticePageSupplementalReading };

const Subtitle = styled.h4`
  margin: -15px 0 30px;
  text-align: center;
  font-size: 14px;
  font-family: ${styles.nationalRegular};
  color: ${styles.textGrayed};
  letter-spacing: 0.01em;
  font-weight: 400;
`;

const Content = styled.div`
  a {
    color: ${styles.primary} !important;

    &:hover {
      color: ${styles.primaryHover} !impotant;
    }
  }

  p {
    margin-bottom: 15px !important;
  }
`;
