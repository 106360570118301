import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Card, Button, withData } from '../components';

const DashboardTooltip = ({ className, tooltip, position, dimensions, updateTooltipStatus, updateUser, practicesCount }) => {
  const [hidden, setHidden] = useState(false);

  const tooltipStyle = {
    top: position.top + (parseInt(dimensions.height) / 2),
    left: position.left + dimensions.width + 20
  };

  const getTooltipContent = () => {
    switch (tooltip) {
      case 'in_progress':
        return (
          <div>
            <h2>How To Track Your Progress</h2>
            <p>
              Your dashboard color codes Best Practices based on progress.
              When a Best Practice is <span>in progress</span>, it will always be orange and will
              show the stage you are currently in.
            </p>
          </div>
        );
      default:
        return (
          <div>
            <h2>Where To Start</h2>
            <p>To begin implementing a Best Practice simply click on any one of the {practicesCount} Best Practices.</p>
          </div>
        );
    }
  };

  const handleOnClick = () => {
    const newStatus = tooltip === 'pending' ? 'in_progress' : null;
    const delay = newStatus === null ? 0 : 300;

    if (hidden) return;
    setHidden(true);

    setTimeout(() => {
      setHidden(false);
      saveTooltipStatus();
      updateTooltipStatus(newStatus);
    }, delay);
  };

  const saveTooltipStatus = () => {
    if (tooltip === 'pending') {
      updateUser({ tooltip_start: true }, true);
    } else if (tooltip === 'in_progress') {
      updateUser({ tooltip_progress: true }, true);
    }
  };

  const classes = [ className ];
  if (hidden) classes.push('hide');

  return (
    <div
      className={classes.join(' ')}
      style={tooltipStyle}
    >
      <Card>
        {getTooltipContent()}
        <Button
          size="sm"
          handleClick={handleOnClick}
        >
          Got it
        </Button>
      </Card>
    </div>
  );
};

const StyledDashboardTooltip = styled(DashboardTooltip)`
  position: absolute;
  transform: translateY(-50%);
  transition: opacity 300ms;

  &.hide {
    opacity: 0;
  }

  .card {
    position: relative;
    padding: 20px;
    max-width: 290px;

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      content: '';
      width: 0;
      height: 0;
      border: solid 10px;
      // border-width: 0 15px 0 0;
      border-color: transparent #fff transparent transparent;
    }

    h2 {
      font-family: ${styles.nationalMedium};
      font-size: 18px;
      color: ${styles.heading};
      margin-bottom: 10px;
    }

    p {
      font-size: 15px;
      margin-bottom: 15px;

      span {
        color: ${styles.secondary};
      }
    }

    .button {
      width: 85px;
      text-align: center;
      display: inline-block;
    }
  }
`;

const DashboardTooltipWithData = withData(StyledDashboardTooltip);
export { DashboardTooltipWithData as DashboardTooltip };
