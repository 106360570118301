import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { BestPracticesCard, ModalDialog } from '../components';
import { dom } from '../../libs';


class BestPracticesList extends React.Component {
  state = {
    cardHeight: null,
    // showAll: false,
    showAll: true,
    showShowAllButton: false,
    showModal: false,
    restrictedTitle: null,
    restrictedReleaseDate: null
  };

  componentDidMount() {
    this.setCardHeights();
    this.setShowAllToggle();
    window.addEventListener('resize', this.resetResizeValues);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetResizeValues);
  }

  setCardHeights = () => {
    this.setState({ showAll: true }, () => {
      const cardHeight = dom.getStandardizedHeight('.best_practices .best_practices_card');
      this.setState({ cardHeight, showAll: false });
    })
  };

  setShowAllToggle = () => {
    const { practices } = this.props;
    const windowWidth = window.innerWidth;
    let cardsPerRow = 5;

    if (windowWidth <= 1450 && windowWidth > 1250) {
      cardsPerRow = 4;
    } else if (windowWidth <= 1250) {
      cardsPerRow = 2
    }

    if (practices.length > cardsPerRow) this.setState({ showShowAllButton: true });
    else this.setState({ showShowAllButton: false });
  };

  resetResizeValues = () => {
    this.setState({ cardHeight: null }, () => this.setCardHeights());
    this.setShowAllToggle();
  };

  toggleShowAll = () => {
    this.setState(prevState => ({ showAll: !prevState.showAll }));
  };



  showRestrictionModal = (restrictedTitle, restrictedReleaseDate) => {
    this.setState({ showModal: true, restrictedTitle, restrictedReleaseDate });
  };

  toggleShowModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  }

  render() {
    const { className, practices } = this.props;
    const {
      cardHeight,
      showAll,
      showShowAllButton,
      showModal,
      restrictedTitle,
      restrictedReleaseDate
    } = this.state;

    const classes = [
      'best_practices',
      className,
      showAll ? 'show_all' : ''
    ];

    return (
      <div className={classes.join(' ')}>
        <div className="float_clear">
          {practices.map(practice => (
            <BestPracticesCard
              key={practice.id}
              practice={practice}
              height={cardHeight}
              showRestrictionModal={this.showRestrictionModal}
            />
          ))}
        </div>
        {false && showShowAllButton && (
          <span
            className={`toggle_all ${showAll ? 'hide' : 'show'}`}
            onClick={this.toggleShowAll}
          >
            {showAll ? 'Hide' : 'Show All'} Best Practices <i />
          </span>
        )}
        <ModalDialog
          show={showModal}
          toggle={this.toggleShowModal}
          text={`${restrictedTitle} will be available on ${restrictedReleaseDate}.`}
        />
      </div>
    );
  }
}

const StyledBestPracticesList = styled(BestPracticesList)`
  padding: 35px 0 0;
  width: calc(100% + 20px);

  .best_practices_card {
    float: left;
    width: 20%;
    padding: 0 20px 20px 0;
  }

  // &:not(.show_all) {
  //   .best_practices_card {
  //     display: none;
  //
  //     @media (min-width: 1451px) {
  //       &:nth-child(-n+5) {
  //         display: block;
  //       }
  //     }
  //
  //     @media (min-width: 1251px) and (max-width: 1450px) {
  //       &:nth-child(-n+4) {
  //         display: block;
  //       }
  //     }
  //
  //     @media (min-width: 0) and (max-width: 1250px) {
  //       &:nth-child(-n+3) {
  //         display: block;
  //       }
  //     }
  //   }
  // }

  @media (max-width: 1450px) {
    .best_practices_card {
      width: 25%;
    }
  }

  @media (max-width: 1250px) {
    .best_practices_card {
      width: 33.3333%;
    }
  }

  .toggle_all {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0.01em;
    color: ${styles.primary};
    transition: color 50ms;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;

      i {
        border-color: ${styles.primaryHover};
        transition: border-color 300ms;
      }
    }

    i {
      display: inline-block;
      border-color: ${styles.primary};
      border-style: solid;
      border-width: 0 0 2px 2px;
      transition: border-color 50ms;
      width: 8px;
      height: 8px;
      transform-origin: center;
      transform: rotate(-45deg) translateY(-4px);
      margin-left: 8px;
    }

    &.hide {
      i {
        transform: rotate(135deg) translateY(-2px);
        margin-left: 5px;
      }
    }
  }
`;

export { StyledBestPracticesList as BestPracticesList };
