import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Modal, LogInForm } from '../components';

const LogInModal = ({ className, show, toggle, logIn }) => (
  <Modal
    className={className}
    show={show}
    toggle={toggle}
    size="sm"
    backdrop
    noCloser
  >
    <h2>Log In</h2>
    <LogInForm logIn={logIn} />
  </Modal>
);

const StyledLogInModal = styled(LogInModal)`
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 500px !important;
      margin: 180px auto 0 !important;
    }
  }

  .modal-body {
    padding: 50px 50px 60px;
  }

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    color: ${styles.heading};
    line-height: 1;
    margin-bottom: 40px;
  }
`;

export { StyledLogInModal as LogInModal };
