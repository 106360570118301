import React from 'react';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';


const ModalWrapper = ({ className, children, show, toggle, size, backdrop, noPadding, noCloser }) => (
  <Modal
    className={className}
    isOpen={show}
    toggle={toggle}
    size={size}
    backdrop={backdrop ? backdrop : true}
  >
    {!noCloser && <i className="closer" onClick={toggle} />}
    <ModalBody className={noPadding ? 'no_padding' : ''}>
      {children}
    </ModalBody>
  </Modal>
);


const StyledModal = styled(ModalWrapper)`
  transition: transform 150ms ease-out !important;

  .modal-content {
    border: none;
  }

  .modal-body {
    padding: 40px 20px 30px;

    &.no_padding {
      padding: 0 !important;
    }
  }

  &.modal-xs {
    @media (min-width: 992px) {
      max-width: 280px;
    }
  }

  &.modal-sm {
    @media (min-width: 992px) {
      max-width: 400px;
    }

    @media (min-width: 576px) {
      max-width: 400px;
    }
  }

  &.modal-md {
    @media (min-width: 992px) {
      max-width: 650px;
    }
  }

  .closer {
    top: 10px;
    right: 10px;
    z-index: 10;
    width: 18px;
    height: 18px;

    // &:hover {
    //   &::before,
    //   &::after {
    //     background-color: #000;
    //   }
    // }
  }
`;

export { StyledModal as Modal };
