import moment from 'moment';
import { getLatestStatus, getCurrentPhase } from '../libs';

const status = {
  addStatuses: practices => {
    return practices.map(practice => {
      const statuses = practice.statuses;
      const phases = practice.phases;

      const practiceStatuses = statuses.filter(status => status.type === 'practice');
      const phaseStatuses = statuses.filter(status => status.type === 'phase');
      const resourceStatuses = statuses.filter(status => status.type === 'resource');

      // Add Current Practice Status //
      practice.status = getLatestStatus(practiceStatuses);

      if (!statuses.length) return practice;

      // Add Practice Statuses //
      practice.statuses = practiceStatuses.map(s => ({
        id: s.id,
        status: s.status,
        timestamp: s.timestamp
      }));

      // Add Practice started_on/completed_on props //
      practice.statuses.forEach(s => {
        if (s.status === 'in_progress') {
          practice.started_on = moment(s.timestamp);
        } else if (s.status === 'complete') {
          practice.completed_on = moment(s.timestamp);
        }
      });

      if (!phases.length) return practice;


      // PHASES //
      practice.phases = phases.map(phase => {
        const resources = phase.resources;
        const pStatuses = phaseStatuses.filter(s => s.phase_id === phase.id);

        switch (phase.section_type) {
          // Only include statuses for `phase` type phase sections //
          case 'phase':
            // Add Phases Statuses //
            phase.statuses = pStatuses.map(s => ({
              id: s.id,
              status: s.status,
              timestamp: s.timestamp
            }));

            // Add Current Phases Status //
            phase.status = getLatestStatus(pStatuses);
            break;
          case 'reading':
            // Mark recommended reading sections as complete //
            phase.status = 'complete';
            break;
          default:
        }

        if (!resources.length) return phase;


        // RESOURCES //
        phase.resources = resources.map(resource => {
          const rStatuses = resourceStatuses.filter(r => r.phase_id === phase.id && r.resource_id === resource.id);

          // Add Resource Statuses //
          resource.statuses = rStatuses.map(s => ({
            id: s.id,
            status: s.status,
            timestamp: s.timestamp
          }));

          // Add Current Resource Status //
          resource.status = getLatestStatus(rStatuses);

          return resource;
        });

        return phase;
      });

      // Add Current Phase //
      practice.currentPhase = getCurrentPhase(practice);

      return practice;
    });
  },

  allResourcesAreComplete: phase => {
    return phase.resources.map(r => r.status).every(status => status === 'complete');
  },

  allPhasesAreComplete: practice => {
    return practice.phases.map(ph => ph.status).every(status => status === 'complete');
  },

  allPracticesArePending: practices => {
    return practices.map(p => p.status).every(status => status === 'pending');
  }
};

const {
  addStatuses,
  allResourcesAreComplete,
  allPhasesAreComplete,
  allPracticesArePending
} = status;

export {
  status,
  addStatuses,
  allResourcesAreComplete,
  allPhasesAreComplete,
  allPracticesArePending
};
