import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  BestPracticePageHero,
  BestPracticePagePhase,
  BestPracticePageMoreResources,
  BestPracticesPageVideo,
  BestPracticesPageDocument,
  ModalDialog,
  BestPracticePageSupplementalReading,
  BestPracticePageSocialShare,
  HelpButton,
  Button
} from '../components';

import { getGettingsStartedSteps } from '../../libs';


class BestPracticePageModal extends React.Component {
  state = {
    showVideo: false,
    videoUrl: null,
    videoType: null,
    showDocument: false,
    documentUrl: null,
    showGettingStartedCheckModal: false,
    showStepCheckModal: false,
    showPracticeCheckModal: false,
    incompletePhaseNumber: null,
    incompletePhaseName: null,
    surveyCheckType: 'pre'
  };

  handleStopPropagation = e => e.stopPropagation();

  handleShowVideo = option => {
    if (!option) return;

    const { video_type, embed_url, file } = option;

    this.setState({
      showVideo: true,
      videoUrl: video_type === 'embeddable' ? embed_url : file,
      videoType: video_type
    });
  };

  handleCloseVideo = () => {
    this.setState({ showVideo: false, videoUrl: null });
  };

  handleShowDocument = documentUrl => {
    this.setState({ showDocument: true, documentUrl });
  };

  handleCloseDocument = () => {
    this.setState({ showDocument: false, documentUrl: null });
  };

  canStartPractice = () => {
    const { practices, practice: { details: { /*position,*/ getting_started_step } } } = this.props;
    // const practicePosition = parseInt(position);
    const gettingsStartedSteps = getGettingsStartedSteps(practices);
    let canStart = true;

    // This is a getting started step. No restriction for getting started steps //
    if (getting_started_step) return true;

    // Check that getting started steps are completed first //
    if (gettingsStartedSteps.length) {
      gettingsStartedSteps.forEach(step => {
        if (step.status !== 'complete') canStart = false;
      });

      if (!canStart) {
        this.setState({ showGettingStartedCheckModal: true });
        return false;
      }
    }

    // Then check that the first 2 BPs are completed //
    // if (practicePosition > 2) {
    //   practices.forEach(p => {
    //     const pos = parseInt(p.details.position);
    //     if (pos < 3 && p.status !== 'complete') canStart = false;
    //   });
    //
    //   if (!canStart) {
    //     this.setState({ showPracticeCheckModal: true });
    //     return false;
    //   }
    // }

    return true;
  };

  canStartPhase = resource => {
    const { practice: { phases, details: { getting_started_step } } } = this.props;
    const { media: { type: resourceType, survey_type: surveyType } } = resource;
    let surveyCheckType = resourceType === 'survey' && surveyType === 'post' ? 'post' : 'pre';
    let canStart = true;

    // Continue if this tool is a pre practice or any getting started step survey //
    if (resourceType === 'survey' && (surveyType === 'pre' || getting_started_step)) return true;

    // Check that pre practice survey(s) are complete //
    phases.forEach(phase => {
      const { resources } = phase;

      resources && resources.forEach(resource => {
        const { status, media: { type, survey_type: toolType } } = resource;
        // resource can have surveyType === post but have been changed to something other than a survey //
        if (type !== 'survey') return;

        if (!getting_started_step) {
          switch (surveyType) {
            case 'post':
              if (toolType !== 'post' && status !== 'complete') canStart = false;
              break;
            default:
              if (toolType === 'pre' && status !== 'complete') canStart = false;
              break;
          }

        // All getting started surveys must be completed first //
        } else if (status !== 'complete') {
          canStart = false;
          surveyCheckType = 'getting_started';
        }
      });
    });

    if (canStart) return true;

    this.setState({ showStepCheckModal: true, surveyCheckType });
    return false;
  };

  // Checks that all previous phases are complete (no longer in use) //

  // canStartPhase = phaseIndex => {
  //   const { practice: { phases } } = this.props;
  //   let index = phaseIndex;
  //   let canStart = true;
  //   let incompletePhaseNumber = null;
  //   let incompletePhaseName = null;
  //
  //   if (index > 0) {
  //     while (index > 0) {
  //       index--;
  //       if (phases[index].status !== 'complete') {
  //         canStart = false;
  //         incompletePhaseNumber = index + 1;
  //         incompletePhaseName = phases[index].title;
  //       }
  //     }
  //   }
  //
  //   if (canStart) return true;
  //   this.setState({
  //     showStepCheckModal: true,
  //     incompletePhaseNumber,
  //     incompletePhaseName
  //   });
  //   return false;
  // };

  toggleGettingStartedCheckModal = () => {
    this.setState(prevState => ({ showGettingStartedCheckModal: !prevState.showGettingStartedCheckModal }));
  };

  toggleStepCheckModal = () => {
    this.setState(prevState => ({ showStepCheckModal: !prevState.showStepCheckModal }));
  };

  togglePracticeCheckModal = () => {
    this.setState(prevState => ({ showPracticeCheckModal: !prevState.showPracticeCheckModal }));
  };

  getGettingStartedStepModalText = () => {
    const { practices } = this.props;
    const gettingsStartedSteps = getGettingsStartedSteps(practices);
    return `The Getting Started step${gettingsStartedSteps.length > 1 ? 's' : ''} must be completed before moving on.`;
  };

  getSurveyRequiredModalText = () => {
    const { practice: { phases } } = this.props;
    const { surveyCheckType } = this.state;
    let surveys = 0;

    phases && phases.forEach(phase => {
      const { resources } = phase;

      resources && resources.forEach(resource => {
        const { status, media: { type } } = resource;

        if (type === 'survey' && status !== 'complete') surveys++;
      });
    });

    switch (surveyCheckType) {
      case 'post':
        return 'All steps in this practice must be completed before you can take the post-practice survey.';
      case 'getting_started':
        return `The pre-program assessment${surveys > 1 ? 's' : ''} must be completed before starting.`;
      default:
        return `The pre-practice survey${surveys > 1 ? 's' : ''} must be completed before moving on.`;
    }
  }

  canDownloadTools = () => {
    const { practice: { phases } } = this.props;
    let canDownloadTools = true;

    if (Array.isArray(phases)) {
      phases.forEach(phase => {
        const { resources } = phase;

        if (Array.isArray(resources)) {
          resources.forEach(resource => {
            const { media: { type, survey_type }, status } = resource;

            if (type === 'survey' && survey_type === 'pre' && status !== 'complete') {
              canDownloadTools = false;
            }
          });
        }
      });
    }

    return canDownloadTools;
  };

  render() {
    const { className, practice, handleCloseModal, absolutelyPositionHelp } = this.props;
    const {
      showVideo,
      videoUrl,
      videoType,
      showDocument,
      documentUrl,
      showGettingStartedCheckModal,
      showStepCheckModal,
      showPracticeCheckModal
      // incompletePhaseNumber,
      // incompletePhaseName
    } = this.state;
    const { quote } = practice;

    return (
      <div
        className={className}
        onClick={e => this.handleStopPropagation(e)}
      >
        <BestPracticePageHero
          practice={practice}
          handleCloseModal={handleCloseModal}
        />
        <div className="practice_content">
          {quote && (quote.hebrew || quote.english) && (
            <div className="bp_quote">
              {quote.hebrew && <div className="bp_quote__hebrew" dangerouslySetInnerHTML={{ __html: quote.hebrew }} />}
              {quote.english && <div className="bp_quote__english" dangerouslySetInnerHTML={{ __html: quote.english }} />}
            </div>
          )}
          <h3>What To Expect</h3>
          <p dangerouslySetInnerHTML={{ __html: practice.description }} />
          {practice.all_practice_tools_file && (
            <div className="download_all_button">
              <Button
                buttonStyle="primary inverted"
                link={practice.all_practice_tools_file}
                tooltip={!this.canDownloadTools() ? 'Please complete the Pre-Practice Survey before downloading tools.' : false}
                disabled={!this.canDownloadTools()}
              >
                <i className="fa fa-download" aria-hidden="true"></i>{' '}Download All
              </Button>
            </div>
          )}
          {practice.phases && (
            <div className="phases_container">
              {practice.phases.map((phase, i) => (
                phase.section_type === 'phase' ? (
                  <BestPracticePagePhase
                    key={phase.id}
                    practice={practice}
                    phase={phase}
                    phaseIndex={i}
                    handleShowVideo={this.handleShowVideo}
                    handleShowDocument={this.handleShowDocument}
                    canStartPractice={this.canStartPractice}
                    canStartPhase={this.canStartPhase}
                    canDownloadTools={this.canDownloadTools()}
                  />
                ) : (
                  <BestPracticePageSupplementalReading
                    key={phase.id}
                    data={{
                      title: phase.title,
                      subtitle: phase.subtitle,
                      content: phase.content
                    }}
                  />
                )
              ))}
            </div>
          )}
          {false && <BestPracticePageMoreResources />}
          <BestPracticePageSocialShare practice={practice} />
        </div>
        {showVideo && <BestPracticesPageVideo videoUrl={videoUrl} videoType={videoType} handleCloseVideo={this.handleCloseVideo} />}
        {showDocument && <BestPracticesPageDocument documentUrl={documentUrl} handleCloseDocument={this.handleCloseDocument} />}
        <ModalDialog
          show={showGettingStartedCheckModal}
          toggle={this.toggleGettingStartedCheckModal}
          text={this.getGettingStartedStepModalText()}
        />
        <ModalDialog
          show={showStepCheckModal}
          toggle={this.toggleStepCheckModal}
          text={this.getSurveyRequiredModalText()}
        />
        <ModalDialog
          show={showPracticeCheckModal}
          toggle={this.togglePracticeCheckModal}
          text="Practices 1 and 2 must be completed before moving on."
        />
        <HelpButton absolute={absolutelyPositionHelp} />
      </div>
    );
  }
}

const StyledBestPracticePageModal = styled(BestPracticePageModal)`
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  min-height: 100%;

  .bp_quote {
    margin: 0 0 40px;
    text-align: center;
    color: ${styles.heading};

    p {
      margin: 0 0 10px;
    }

    &__hebrew {
      p {
        font-size: 21px;
      }
    }

    &__english {
      p {
        font-size: 14px;
      }
    }
  }

  .practice_content {
    padding: 70px 30px 100px;
    max-width: 1000px;
    margin: auto;

    h3 {
      font-weight: normal;
      color: ${styles.heading};
      font-size: 20px;
      font-family: ${styles.nationalMedium};
      letter-spacing: 0.01em;
      text-align: center;
      margin: 0 0 25px;
    }

    p {
      margin: 0 0 15px;
      letter-spacing: 0.02em;
      line-height: 1.8;
    }
  }

  .phases_container {
    margin-top: 60px;
    padding: 20px 0 50px;
    border: solid #e3e5ee;
    border-width: 1px 0 0;
  }

  .download_all_button {
    text-align: center;
    margin-top: 30px;
  }
`;

export { StyledBestPracticePageModal as BestPracticePageModal };
