import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';

import { withData } from '../components';


const PageTabs = ({ className, tabs, history, getUser }) => {
  const user = getUser();
  const isAdmin = user.role === 'admin';

  return (
    <ul className={className}>
      {tabs && tabs.map((tab, i) => {
        if (tab.adminOnly && !isAdmin) return null;
        return <li key={tab.url + i}><NavLink exact to={tab.url}>{tab.label}</NavLink></li>;
      })}
    </ul>
  );
};


const StyledPageTabs = styled(PageTabs)`
  width: 100%;
  list-style-type: none;
  padding-bottom: 20px;
  border-bottom: 1px solid ${styles.borderDark};

  li {
    display: inline-block;
    position: relative;

    &:not(:first-child) {
      padding-left: 15px;
    }

    &:not(:last-child) {
      padding-right: 15px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
        height: 13px;
        width: 1px;
        background-color: ${styles.borderDark};
      }
    }
  }

  a {
    &.active {
      color: ${styles.primary};
    }
  }
`;

const PageTabsWithData = withData(StyledPageTabs);
export { PageTabsWithData as PageTabs };
