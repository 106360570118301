import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { LogOutLink, withData } from '../components';


const Header = ({ className, title, getUser }) => {
  const user = getUser();

  return (
    <div className={`${className} float_clear`}>
      {title && <h1>{title}</h1>}

      <div className="account_container">
        <span className="user_name">{user.first_name} {user.last_name}</span>
        <LogOutLink />
      </div>
    </div>
  );
}


const StyledHeader = styled(Header)`
  margin-bottom: 50px;

  h1 {
    float: left;
    color: ${styles.heading};
  }

  .account_container {
    float: right;
    padding-top: 25px;
    margin-left: 20px;
  }

  .user_name {
    display: block;
    font-size: 14px;
    margin-bottom: 1px;
  }
`;

const HeaderWithData = withData(StyledHeader);
export { HeaderWithData as Header };
