import React from 'react';

import {
  Header,
  PageTabs,
  AccountUpdateForm
} from '../components';

const pageTabs = [
  { url: '/account', label: 'Manage Account' },
  { url: '/account/password', label: 'Update Password' }
];


export const Account = ({ user }) => {
  if (user && user.role === 'admin' && pageTabs.length === 2) {
    pageTabs.splice(1, 0, {
      url: '/account/organization',
      label: 'Manage Organization'
    });
  }

  return (
    <div>
      <Header title="My Account" />
      <PageTabs tabs={pageTabs} />
      <AccountUpdateForm />
    </div>
  );
};
