import React from 'react';

import { Modal, TeamEditMemberModalForm } from '../components';


const TeamEditMemberModal = ({ className, teamMembers, member, showConfirmation, show, toggle }) => (
  <Modal
    className={className}
    show={show}
    toggle={toggle}
    size="md"
    noPadding
  >
    <TeamEditMemberModalForm
      teamMembers={teamMembers}
      member={member}
      toggleModal={toggle}
      showConfirmation={showConfirmation}
    />
  </Modal>
);


export { TeamEditMemberModal };
