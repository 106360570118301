import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Input, withData } from '../components';

import { POST } from '../../libs';


class LogInPwReset extends React.Component {
  state = {
    username: '',
    usernameInvalid: false,
    loading: false,
    showConfirmation: false
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLogInSubmit = e => {
    // const { saveCookies, logIn } = this.props;
    const { username } = this.state;
    e.preventDefault();

    if (username) {
      this.setState({ loading: true, usernameInvalid: false });

      POST.sendPwResetEmail(username)
        .then(res => {
          console.log(res);
          this.setState({ showConfirmation: true, loading: false });
        })
        .catch(err => {
          console.error(err.message);
          this.setState({ showConfirmation: true, loading: false });
        });
    } else {
      this.setState({ usernameInvalid: true });
    }
  };

  render() {
    const { className, showLoginForm } = this.props;
    const { username, usernameInvalid, loading, showConfirmation } = this.state;

    return (
      <form className={className} onSubmit={e => this.handleLogInSubmit(e)}>
        {showConfirmation ? (
          <p>Please check your email for a password reset link.</p>
        ) : (
          <p>Please enter your email address. You will receive a link to create a new password via email.</p>
        )}
        <div className="form_row">
          <Input
            name="username"
            placeholder="Your Email"
            value={username}
            onChange={this.handleInputChange}
            errorMessage="Enter a valid email."
            shouldSetError={usernameInvalid}
            required
          />
        </div>
        <div className="form_row">
          <Input
            type="submit"
            name="submit"
            inputClassName="button primary shadow full_width"
            value="Reset Password"
            placeholder="Log In"
            disabled={loading}
          />
        </div>
        <div className="form_row">
          <span className="show_login" onClick={showLoginForm}>Back</span>
        </div>
      </form>
    );
  }
}

const StyledLogInPwReset = styled(LogInPwReset)`
  .form_error {
    margin: 0 0 20px;
    font-size: 14px;
    text-align: center;
    color: ${styles.error};
  }

  .reset_password {
    position: absolute;
    right: 17px;
    top: 14px;
    z-index: 1;
    font-size: 12px;
    color: ${styles.primary};
    transition: color 50ms;
    cursor: pointer;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }

  .form_row {
    &.password {
      position: relative;

      input[type="password"] {
        padding-right: 80px;
      }
    }
  }

  .show_login {
    display: inline-block;
    margin-top: 20px;
    font-size: 12px;
    color: ${styles.primary};
    transition: color 50ms;
    cursor: pointer;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }
`;

const LogInPwResetWithData = withData(StyledLogInPwReset);
export { LogInPwResetWithData as LogInPwReset };
