import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

const HelpButton = ({ className, absolute }) => {
  const [isHover, setHover] = useState(false);
  const [showTooltip, setTooltip] = useState(false);

  const classes = [className];

  if (showTooltip) classes.push('hover');
  if (absolute) classes.push('absolute');

  const handleIsHover = shouldHover => {
    if (shouldHover) {
      setHover(true);
      setTimeout(() => setTooltip(true), 10);
    } else {
      setTooltip(false);
      setTimeout(() => setHover(false), 200);
    }
  };


  return (
    <a
      href={`${process.env.REACT_APP_API_BASE}/contact`}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.join(' ')}
      onMouseOver={() => handleIsHover(true)}
      onMouseLeave={() => handleIsHover(false)}
    >
      {isHover && (
        <span className="text">Have a question{'?'}<br />Send us a message.</span>
      )}
      <span className="qmark">?</span>
    </a>
  );
}

const StyledHelpButton = styled(HelpButton)`
  background-color: ${styles.primary};
  border-radius: 25px;
  min-width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  // right: 70px;
  left: 70px;
  z-index: 100000;
  cursor: pointer;
  transition: background-color 50ms;
  box-shadow: 0px 0px 15px 0px rgba(45, 47, 56, 0.2);
  color: #fff;

  &:hover {
    background-color: ${styles.primaryHover};
    transition: background-color 300ms;
    color: #fff;
  }

  &.hover {
    .text {
      transition: opacity 300ms;
      opacity: 1;
    }
  }

  &.absolute {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  .text {
    opacity: 0;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
    background-color: rgba(0,0,0,0.6);
    line-height: 1.2;
    padding: 8px 10px 10px;
    border-radius: 3px;
    white-space: nowrap;
    transition: opacity 50ms;

    &::after {
      content: '';
      border: 6px solid;
      border-color: transparent rgba(0,0,0,0.6) transparent transparent;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
    }
  }

  .qmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -3px;
    margin-right: -5px;
    font-size: 35px;
    line-height: 1;
  }
`;

export { StyledHelpButton as HelpButton };
