import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

const RadioButton = ({ className, value, name, checked, handleSelectAnswer }) => (
  <label className={`radio_button ${className}`}>
    <input type="radio" value={value} name={name} checked={checked} onChange={e => handleSelectAnswer(name, value)} />
    <i className="radio_button" />
  </label>
);

const StyledRadioButton = styled(RadioButton)`
  position: relative;
  user-select: none;
  width: 16px;
  height: 16px;

  input {
    appearance: none;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }

  .radio_button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid ${styles.borderDark};
  }

  input:checked ~ .radio_button {
    background-color: ${styles.primary};
    border-color: ${styles.primary};

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
`;

export { StyledRadioButton as RadioButton };
