import React from 'react';

import { SectionTitle, Input } from '../components';
import { validate } from '../../libs';


export const AccountUpdateFormDetails = ({ data, onInputChange, onValidate, isAdmin, valid, emailErrorMessage }) => {
  const {
    email,
    first_name,
    last_name,
    title,
    phone
  } = data;

  return (
    <div>
      <SectionTitle title="Account Details" />
      <div className="input_row with_label">
        <label>Email</label>
        <Input
          name="email"
          value={email}
          onChange={onInputChange}
          onValidate={onValidate}
          errorMessage={emailErrorMessage}
          shouldSetError={!validate.email(email)}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>First Name</label>
        <Input
          name="first_name"
          value={first_name}
          onChange={onInputChange}
          onValidate={onValidate}
          shouldSetError={!valid.firstName}
          errorMessage="Enter a valid first name."
          required
        />
      </div>
      <div className="input_row with_label">
        <label>Last Name</label>
        <Input
          name="last_name"
          value={last_name}
          onChange={onInputChange}
          onValidate={onValidate}
          errorMessage="Enter a valid last name."
          shouldSetError={!valid.lastName}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>Role/Title</label>
        <Input
          name="title"
          value={title}
          onChange={onInputChange}
        />
      </div>
      <div className="input_row with_label">
        <label>Phone</label>
        <Input
          name="phone"
          value={phone}
          onChange={onInputChange}
          onValidate={onValidate}
          errorMessage="Enter a valid phone number."
          shouldSetError={!valid.phone}
          required
        />
      </div>
    </div>
  );
};
