import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logoH from '../../assets/images/logo-horizontal.png';
import logoV from '../../assets/images/logo-vertical.png';
import logoPH from '../../assets/images/logo-placeholder.png';


const Logo = ({ className, size, layout, url, anchor, target = '_self', logoStyle = 'default' }) => {
  const classNames = [ 'logo', className, size, layout ];
  const imgSrc = layout === 'vertical' ? logoV : logoH;
  let logoImage;

  switch (logoStyle) {
    case 'placeholder':
      logoImage = () => <img src={logoPH} className="logo" alt="Sacred Spaces Logo" />;
      break;
    default:
      logoImage = () => <img src={imgSrc} className="logo" alt="Sacred Spaces Logo" />;
  }

  const getLogoLinkType = () => {
    if (typeof url === 'string' && url !== '') {
      return <Link to={url}>{logoImage()}</Link>;
    } else if (typeof anchor === 'string' && anchor !== '') {
      return (
        <a href={anchor} target={target}>{logoImage()}</a>
      );
    } else {
      return logoImage();
    }
  }

  return (
    <div className={classNames.join(' ')}>
      {getLogoLinkType()}
    </div>
  );
};

const StyledLogo = styled(Logo)`
  width: 170px;
  margin: auto;

  img {
    width: 100%;
  }

  &.md {

  }
`;


export { StyledLogo as Logo };
