import React from 'react';

import {
  SectionTitle,
  Input,
  Button,
  ModalDialog,
  withData
} from '../components';

import { POST } from '../../libs';

const defaultErrorMessage = 'Enter a valid password.';

const defaultValidFields = {
  oldPassword: true,
  newPassword: true,
  confirmPassword: true
};

const defaultValidation = {
  validFields: defaultValidFields,
  newPwErrorMessage: defaultErrorMessage,
  oldPwErrorMessage: defaultErrorMessage
};


class AccountPasswordUpdateForm extends React.Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    showSuccessModal: false,
    loading: false,
    ...defaultValidation
  };

  toggleSuccessModal = () => {
    this.setState(prevState => ({ showSuccessModal: !prevState.showSuccessModal }));
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleUpdatePassword = () => {
    const { getUser } = this.props;
    const { oldPassword, newPassword, newPasswordConfirm } = this.state;
    const validFields = Object.assign({}, defaultValidFields);
    const user = getUser();

    this.setState({ ...defaultValidation }, () => {
      if (oldPassword && newPassword && newPasswordConfirm) {
          if (newPassword !== newPasswordConfirm) {
            validFields.newPassword = false;
            validFields.confirmPassword = false;

            this.setState({ validFields, newPwErrorMessage: 'Passwords do not match.' });
          } else {
            this.setState({ loading: true });

            POST.checkPassword(user.id, oldPassword)
              .then(({ data: isValid }) => {
                if (isValid) {
                  POST.updatePw(user.id, newPassword)
                    .then(res => this.setState({
                      ...defaultValidation,
                      oldPassword: '',
                      newPassword: '',
                      newPasswordConfirm: '',
                      showSuccessModal: true,
                      loading: false
                    }))
                    .catch(err => {
                      this.setState({ loading: false });
                      console.error(err);
                    });
                } else {
                  validFields.oldPassword = false;
                  this.setState({ validFields, oldPwErrorMessage: 'Incorrect password.', loading: false });
                }
              })
              .catch(err => {
                this.setState({ loading: false });
                console.error(err);
              });
          }
      } else {
        if (!oldPassword) validFields.oldPassword = false;
        if (!newPassword) validFields.newPassword = false;
        if (!newPasswordConfirm) validFields.confirmPassword = false;
        this.setState({ validFields });
      }
    });
  };

  handleInputValidate = (name, value) => {
    console.log(name, value, this.state.validFields[name])
    if (this.state.validFields[name] !== value) {
      this.setState(prevState => ({
        validFields: {
          ...prevState.validFields,
          [name]: value
        }
      }));
    }
  };

  render() {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm,
      validFields,
      newPwErrorMessage,
      oldPwErrorMessage,
      loading,
      showSuccessModal
    } = this.state;

    const isInvalid = oldPassword === '' || newPassword === '' || newPasswordConfirm === '';

    return (
      <div>
        <form noValidate className="manage_form">
          <SectionTitle title="Update Password" />
          <div className="input_row with_label">
            <label>Old Password</label>
            <Input
              name="oldPassword"
              type="password"
              value={oldPassword}
              onChange={this.handleInputChange}
              onValidate={this.handleInputValidate}
              errorMessage={oldPwErrorMessage}
              shouldSetError={!validFields.oldPassword}
              required
            />
          </div>
          <div className="input_row with_label">
            <label>New Password</label>
            <Input
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={this.handleInputChange}
              onValidate={this.handleInputValidate}
              errorMessage={newPwErrorMessage}
              shouldSetError={!validFields.newPassword}
              required
            />
          </div>
          <div className="input_row with_label long_label">
            <label>Confirm New Password</label>
            <Input
              name="newPasswordConfirm"
              type="password"
              value={newPasswordConfirm}
              onChange={this.handleInputChange}
              onValidate={this.handleInputValidate}
              errorMessage={newPwErrorMessage}
              shouldSetError={!validFields.confirmPassword}
              required
            />
          </div>
          <div className="button_container">
            <Button
              handleClick={this.handleUpdatePassword}
              loading={loading}
              disabled={loading || isInvalid}
              shadow
            >
              Update Password
            </Button>
          </div>
        </form>
        <ModalDialog
          show={showSuccessModal}
          toggle={this.toggleSuccessModal}
          text="Your password has been updated."
        />
      </div>
    );
  }
}


const AccountPasswordUpdateFormWithData = withData(AccountPasswordUpdateForm);
export { AccountPasswordUpdateFormWithData as AccountPasswordUpdateForm };
