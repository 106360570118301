import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  AccountOrganizationFormDetails,
  Button,
  ModalDialog,
  withData
} from '../components';

const DEFAULT_GROUP_STATE = {
  name: true,
  city: true,
  state: true,
  zip_code: true,
  country: true,
  organization_type: true,
  organization_type_other: true,
  organization_denomination: true,
  organization_denomination_other: true,
  orthodox_denomination: true,
  orthodox_denomination_other: true,
  organization_logo: true,
  website: true,
  why_joined: true
};


const AccountOrganizationForm = props => {
  const { className, getGroup, updateGroup } = props;
  const group = getGroup();
  // console.log(data.groups);
  // console.log(group);

  const groupHasLoaded = useRef(false);
  const [groupState, setGroupState] = useState(DEFAULT_GROUP_STATE);
  const [validFields, setValidFields] = useState(DEFAULT_GROUP_STATE);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (group && !groupHasLoaded.current) {
      setGroupState({ ...group, should_upload_logo: false });
      groupHasLoaded.current = true;
    }
  }, [group]);

  const handleInputChange = (name, value) => {
    if (typeof name === 'object') {
      setGroupState(prev => ({ ...prev, ...name }));
    } else {
      setGroupState(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleCheckboxChange = (name, value, required) => {
    setGroupState(prevState => {
      const values = prevState[name] ? prevState[name].split(',') : [];

      const newValue = (
        values.includes(value)
          ? values.filter(v => v !== value)
          : values.concat([value])
      ).join(',');

      const newValid = { [name]: !(required && newValue === '') };
      if (!newValue.split(',').includes('Other')) newValid[`${name}_other`] = true;

      setValidFields(prevValid => ({ ...prevValid, ...newValid }));

      return { ...prevState, [name]: newValue };
    });
  };

  const formIsValid = () => {
    const types = groupState.organization_type
      ? groupState.organization_type.split(',')
      : [];
    const denominations = groupState.organization_denomination
      ? groupState.organization_denomination.split(',')
      : [];
    const orthodoxTypes = groupState.orthodox_denomination
      ? groupState.orthodox_denomination.split(',')
      : [];
    const valid = Object.assign({}, DEFAULT_GROUP_STATE);

    const validFields = {
      ...valid,
      name: groupState.name !== '',
      zip_code: groupState.zip_code !== '',
      organization_type: groupState.organization_type !== '',
      organization_type_other: !(groupState.organization_type_other === '' && types.includes('Other')),
      organization_denomination: groupState.organization_denomination !== '',
      organization_denomination_other: !(groupState.organization_denomination_other === '' && denominations.includes('Other')),
      orthodox_denomination: groupState.orthodox_denomination !== '',
      orthodox_denomination_other: !(groupState.orthodox_denomination_other === '' && orthodoxTypes.includes('Other'))
    };

    setValidFields(validFields);
    return Object.values(validFields).every(v => v);
  };

  const handleInputValidate = (name, value) => {
    setValidFields(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdateOrganization = () => {
    console.log(groupState);

    if (formIsValid()) {
      setLoading(true);
      updateGroup(groupState)
        .then(res => {
          setLoading(false);
          setShowConfirmationModal(true);
          console.log('res', res);
        });
    }
  };

  const isValid = Object.values(validFields).every(f => f);

  return (
    <div className={className}>
      <form noValidate className="manage_form">
        <AccountOrganizationFormDetails
          data={groupState}
          onInputChange={handleInputChange}
          onCheckBoxChange={handleCheckboxChange}
          onRemoveLogo={() => handleInputChange('organization_logo', '')}
          onAddLogo={file => handleInputChange({ 'organization_logo': file, 'should_upload_logo': true })}
          onValidate={handleInputValidate}
          valid={validFields}
        />
        <div className="button_container">
          <Button
            handleClick={handleUpdateOrganization}
            loading={loading}
            disabled={loading || !isValid}
            shadow
          >
            Update Organization
          </Button>
        </div>
      </form>
      <ModalDialog
        show={showConfirmationModal}
        toggle={() => setShowConfirmationModal(prev => !prev)}
        text="Your organization has been updated."
      />
    </div>
  );
};

const StyledAccountOrganizationForm = styled(AccountOrganizationForm)`
  // for adding columns //
  form.manage_form {
    max-width: 100%;
  }
`;

const AccountOrganizationFormWithData = withData(StyledAccountOrganizationForm);
export { AccountOrganizationFormWithData as AccountOrganizationForm };
