export const dom = {
  select: selector => {
    return document.querySelector(selector);
  },

  selectAll: selector => {
    const elements = document.querySelectorAll(selector);
    return Array.prototype.slice.call(elements);
  },

  getStandardizedHeight: selector => {
    const elements = dom.selectAll(selector);
    let maxHeight = 0;

    elements.forEach(el => {
      const height = el.offsetHeight;
      if (height > maxHeight) maxHeight = height;
    });

    return maxHeight + 'px';
  }
};
