import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { withData } from '../components';


const LogOutLink = ({ className, removeCookies }) => {
  const logOut = () => {
    removeCookies();
    window.location.href = process.env.REACT_APP_API_BASE + '/';
  };

  return (
    <div className={className}>
      <span onClick={logOut}>Logout</span>
    </div>
  );
}


const StyledLogOutLink = styled(LogOutLink)`
  span {
    color: ${styles.primary};
    font-family: ${styles.nationalBold};
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: color 50ms;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }
`;

const LogOutLinkWithData = withData(StyledLogOutLink);
export { LogOutLinkWithData as LogOutLink };
