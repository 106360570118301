import React from 'react';
import styled from 'styled-components';


const SelectBox = ({ className, options, value, name, handleSelectAnswer }) => (
  <div className={`select_box ${className}`}>
    <select onChange={e => handleSelectAnswer(name, e.target.value)} value={value}>
      {options && options.map((option, i) => (
        <option key={i} value={option}>{option}</option>
      ))}
    </select>
  </div>
);


const StyledSelectBox = styled(SelectBox)`
  position: relative;

  &::after {
    content: '\f0d7';
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

export { StyledSelectBox as SelectBox };
