import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Modal, Button } from '../components';

const BestPracticePageSurveyModal = ({ className, show, toggle, handleSubmitSurvey, media, loading }) => (
  <Modal
    className={className}
    show={show}
    toggle={toggle}
    size="sm"
    backdrop="static"
    noCloser
  >
    <p>You will not be able to update your answers after submitting this {media.type === 'form' ? 'form' : 'survey'}.</p>
    <div className="button_container">
      <Button
        className="button__cancel"
        buttonStyle="secondary outline"
        handleClick={toggle}
        disabled={loading}
      >Cancel</Button>
      <Button
        className="button__submit"
        handleClick={handleSubmitSurvey}
        loading={loading}
        disabled={loading}
      >Submit</Button>
    </div>
  </Modal>
);


const StyledBestPracticePageSurveyModal = styled(BestPracticePageSurveyModal)`
  text-align: center;

  p {
    margin: -5px 0 20px;
    color: ${styles.headingLight};
    font-size: 17px;
  }

  .button {
    min-width: 120px;
    margin-top: 10px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    &__cancel {
      float: left;
    }

    &__submit {
      float: right;
    }
  }

  .loader {
    margin-left: 0;
    position: absolute;
    right: 154px;
    bottom: 42px;
  }
`;

export { StyledBestPracticePageSurveyModal as BestPracticePageSurveyModal };
