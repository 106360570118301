import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const ToggleButtons = ({ className, name, values, active, onChange }) => {
  if (!values || !(values instanceof Object)) return null;

  const classes = [ className, 'float_clear' ];

  const handleOnChange = value => {
    onChange(name, value);
  };

  return (
    <ul className={classes.join(' ')}>
      {Object.keys(values).map(key => (
        <li
          className={key === active ? 'active' : ''}
          key={key}
          onClick={() => handleOnChange(key)}
        >
          {values[key]}
        </li>
      ))}
    </ul>
  );
}


const StyledToggleButtons = styled(ToggleButtons)`
  margin-bottom: 20px;

  li {
    float: left;
    font-size: 16px;
    padding: 13px 15px 14px;
    border: 1px solid ${styles.border};
    color: ${styles.headingLight};
    line-height: 1;
    cursor: pointer;
    transition: color 50ms, background-color 50ms, border-color 50ms;

    &:hover {
      transition: color 300ms, background-color 300ms, border-color 300ms;
    }

    &.active {
      color: #fff;
      background-color: ${styles.primary};
      border-color: ${styles.primary};

      &:hover {
        background-color: ${styles.primaryHover};
        border-color: ${styles.primaryHover};
      }
    }

    &:not(.active) {
      &:hover {
        color: ${styles.primary};
      }
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export { StyledToggleButtons as ToggleButtons };
