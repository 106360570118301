import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const Card = ({ className, children, handleOnMouseEnter, handleOnMouseLeave, handleOnClick }) => (
  <div
    className={`${className} card`}
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}
    onClick={handleOnClick}
  >
    {children}
  </div>
);


const StyledCard = styled(Card)`
  border: 1px solid ${styles.textLight};
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
  transition: box-shadow 300ms;
`;

export { StyledCard as Card };
