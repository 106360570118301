import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  DashboardTooltip,
  BestPracticesCard,
  withData
} from '../components';

import { dom } from '../../libs';


class DashboardTooltipContainer extends React.Component {
  state = {
    showOverlay: false,
    showTooltip: false,
    bPList: null,
    practice: null,
    cardPosition: null,
    cardDimensions: null
  };

  componentDidMount() {
    const { practices } = this.props;
    this.setState({ practice: Object.assign({}, practices[0]) }, () => this.getCardAttributes());

    setTimeout(() => this.setState({ showOverlay: true }), 100);
    setTimeout(() => this.setState({ showTooltip: true }), 450);

    window.addEventListener('resize', this.getCardAttributes);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getCardAttributes);
  }

  getCardAttributes = () => {
    const { bPList } = this.props;
    const firstBPCard = bPList.querySelector('.best_practices_card .card');

    if (firstBPCard) {
      const rect = firstBPCard.getBoundingClientRect();
      this.setState({
        cardPosition: { top: rect.top, left: rect.left },
        cardDimensions: {
          width: firstBPCard.offsetWidth,
          height: dom.getStandardizedHeight('.best_practices .best_practices_card .card')
        }
      });
    }
  };

  getBestPracticeCount = () => {
    const { practices } = this.props;
    return practices.filter(p => !p.details.getting_started_step).length;
  };

  render() {
    const { showOverlay, showTooltip, cardPosition, cardDimensions, practice } = this.state;
    const { className, updateTooltipStatus, tooltip } = this.props;
    const classes = [ className ];

    if (showOverlay) classes.push('show_overlay');
    if (showTooltip) classes.push('show_tooltip');

    if (practice) practice.status = tooltip;

    return (
      <div className={classes.join(' ')}>
        <div className="tooltip_container">
          {practice && cardPosition && cardDimensions && (
            <Fragment>
              <DashboardTooltip
                tooltip={tooltip}
                position={cardPosition}
                dimensions={cardDimensions}
                updateTooltipStatus={updateTooltipStatus}
                practicesCount={this.getBestPracticeCount()}
              />

              <BestPracticesCard
                position={cardPosition}
                dimensions={cardDimensions}
                practice={practice}
                isForTooltip
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}


const StyledDashboardTooltipContainer = styled(DashboardTooltipContainer)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.8);
  opacity: 0;
  transition: opacity 300ms;

  &.show_overlay {
    opacity: 1;
  }

  & > .tooltip_container {
    opacity: 0;
    transition: opacity 300ms;
  }

  &.show_tooltip {
    & > .tooltip_container {
      opacity: 1;
    }
  }
`;

const DashboardTooltipContainerWithData = withData(StyledDashboardTooltipContainer);
export { DashboardTooltipContainerWithData as DashboardTooltipContainer };
