import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const GlossaryTerm = ({ className, term }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <li className={className}>
      <div
        className={`word ${open && 'open'}`}
        onClick={toggleOpen}
      >
        {term.term}
        <i className="plus" />
      </div>
      {open && (
        <div className="definition" dangerouslySetInnerHTML={{ __html: term.definition }} />
      )}
    </li>
  );
}


const StyledGlossaryTerm = styled(GlossaryTerm)`
  & > div {
    position: relative;
    font-size: 16px;
    border: solid ${styles.borderLight};
    border-width: 1px 1px 0;

    &:last-child {
      border-width: 1px;
    }

    &.word {
      color: ${styles.heading};
      font-family: ${styles.nationalMedium};
      padding: 15px 30px;
      background-color: #fff;
      cursor: pointer;
      transition: color 50ms;

      &:hover,
      &.open {
        color: ${styles.primary};
        transition: color 300ms;
      }

      .plus {
        position: absolute;
        top: 50%;
        right: 30px;
        margin-top: -7px;
        width: 14px;
        height: 14px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin-top: -1px;
          height: 2px;
          transform-origin: center;
          background-color: ${styles.borderDark};
        }

        &::after {
          transform: rotate(90deg);
        }
      }
    }

    &.definition {
      padding: 20px 30px 25px;
      background-color: #f6f8fb;
    }
  }

  ul {
    padding-left: 15px;
    list-style-type: disc;
    margin: 5px 0;

    ul {
      list-style-type: circle;
    }
  }

  & > ul {
    margin: 10px 0;
  }
`;

export { StyledGlossaryTerm as GlossaryTerm };
