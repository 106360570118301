import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  SectionTitle,
  Input,
  SelectBox,
  CheckBox,
  Textarea,
  LogoUploader
} from '../components';
import {
  LIMITED_STATES,
  LIMITED_COUNTRIES,
  GROUP_TYPES,
  DENOMINATION_TYPES,
  ORTHODOX_TYPES
} from '../../constants';


const AccountOrganizationFormDetails = props => {
  const {
    className,
    data,
    onInputChange,
    onCheckBoxChange,
    onRemoveLogo,
    onAddLogo,
    onValidate,
    valid
  } = props;

  const {
    name,
    city,
    state,
    zip_code,
    country,
    website,
    why_joined,
    organization_type,
    organization_type_other,
    organization_denomination,
    organization_denomination_other,
    orthodox_denomination,
    orthodox_denomination_other,
    organization_logo
  } = data;

  const organizationTypes = organization_type && organization_type.length
    ? organization_type.split(',')
    : [];
  const denominationTypes = organization_denomination && organization_denomination.length
    ? organization_denomination.split(',')
    : [];
  const orthodoxTypes = orthodox_denomination && orthodox_denomination.length
    ? orthodox_denomination.split(',')
    : [];

  // console.log(data);

  return (
    <div className={`${className} float_clear`}>
      <SectionTitle title="Organization Details" />

    <div className="column">
      <div className="input_row with_label">
        <label>Name</label>
        <Input
          name="name"
          value={name}
          onChange={onInputChange}
          onValidate={onValidate}
          shouldSetError={!valid.name || !name}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>City</label>
        <Input
          name="city"
          value={city}
          onChange={onInputChange}
          onValidate={onValidate}
          shouldSetError={!valid.city || !city}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>State</label>
        <SelectBox
          name="state"
          options={LIMITED_STATES}
          value={state}
          handleSelectAnswer={(name, value) => onInputChange(name, value)}
        />
      </div>
      <div className="input_row with_label">
        <label>Zipcode</label>
        <Input
          name="zip_code"
          value={zip_code}
          onChange={onInputChange}
          onValidate={onValidate}
          shouldSetError={!valid.zip_code || !zip_code}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>Country</label>
        <SelectBox
          name="country"
          options={LIMITED_COUNTRIES}
          value={country}
          handleSelectAnswer={(name, value) => onInputChange(name, value)}
        />
      </div>
      <div className="input_row with_label">
        <label>Why Did You Join?</label>
        <Textarea
          name="why_joined"
          value={why_joined}
          onChange={onInputChange}
          onValidate={onValidate}
          shouldSetError={!valid.why_joined || !why_joined}
          rows={4}
          required
        />
      </div>
      <div className="input_row with_label">
        <label>Logo</label>
        <LogoUploader
          logo={organization_logo}
          onRemoveLogo={onRemoveLogo}
          onAddLogo={onAddLogo}
        />
      </div>
      <div className="input_row with_label">
        <label>Website</label>
        <Input
          name="website"
          value={website}
          onChange={onInputChange}
        />
      </div>
    </div>
    <div className="column">
      <div
        className={
          ['input_row', 'with_label', !valid.organization_type ? 'invalid' : ''].join(' ').trim()
        }
      >
        <label>Type</label>
        <div className="checkbox_group">
          {GROUP_TYPES.map(type => (
            <div key={type.replace(' ', '')}>
              <CheckBox
                name="organization_type"
                label={type}
                value={type}
                checked={organizationTypes.includes(type)}
                handleSelectAnswer={(name, value) => onCheckBoxChange(name, value, true)}
              />
            </div>
          ))}
        </div>
        {!valid.organization_type && <span className="error_message">This field is required.</span>}
      </div>
      {organizationTypes.includes('Other') && (
        <div className="input_row with_label">
          <label>Type - Other</label>
          <Input
            name="organization_type_other"
            value={organization_type_other}
            onChange={onInputChange}
            onValidate={onValidate}
            shouldSetError={
              organizationTypes.includes('Other') && (!valid.organization_type_other || !organization_type_other)
            }
            required
          />
        </div>
      )}
      <div
        className={
          ['input_row', 'with_label', !valid.organization_denomination ? 'invalid' : ''].join(' ').trim()
        }
      >
        <label>Denomination</label>
        <div className="checkbox_group">
          {DENOMINATION_TYPES.map(type => (
            <div key={type.replace(' ', '')}>
              <CheckBox
                name="organization_denomination"
                label={type}
                value={type}
                checked={denominationTypes.includes(type)}
                handleSelectAnswer={(name, value) => onCheckBoxChange(name, value, true)}
              />
            </div>
          ))}
        </div>
        {!valid.organization_denomination && <span className="error_message">This field is required.</span>}
      </div>
      {denominationTypes.includes('Other') && (
        <div className="input_row with_label">
          <label>Denomination - Other</label>
          <Input
            name="organization_denomination_other"
            value={organization_denomination_other}
            onChange={onInputChange}
            onValidate={onValidate}
            shouldSetError={
              denominationTypes.includes('Other') && (!valid.organization_type_other || !organization_denomination_other)
            }
            required
          />
        </div>
      )}
      {denominationTypes.includes('Orthodox') && (
        <>
          <div
            className={
              ['input_row', 'with_label', !valid.orthodox_denomination ? 'invalid' : ''].join(' ').trim()
            }
          >
            <label>Orthodox Denomination</label>
            <div className="checkbox_group">
              {ORTHODOX_TYPES.map(type => (
                <div key={type.replace(' ', '')}>
                  <CheckBox
                    name="orthodox_denomination"
                    label={type}
                    value={type}
                    checked={orthodoxTypes.includes(type)}
                    handleSelectAnswer={(name, value) => onCheckBoxChange(name, value, true)}
                  />
                </div>
              ))}
            </div>
            {!valid.orthodox_denomination && <span className="error_message">This field is required.</span>}
          </div>
          {orthodoxTypes.includes('Other') && (
            <div className="input_row with_label">
              <label>Orthodox - Other</label>
              <Input
                name="orthodox_denomination_other"
                value={orthodox_denomination_other}
                onChange={onInputChange}
                onValidate={onValidate}
                shouldSetError={
                  orthodoxTypes.includes('Other') && (!valid.organization_type_other || !orthodox_denomination_other)
                }
                required
              />
            </div>
          )}
        </>
      )}
      </div>
    </div>
  );
};

const StyledAccountOrganizationFormDetails = styled(AccountOrganizationFormDetails)`
  // for adding columns //
  .column {
    float: left;
    width: 50%;
    max-width: 580px;
    padding-right: 40px;

    &:last-child {
      padding-left: 40px;
      padding-right: 0;
    }

    @media (max-width: 1250px) {
      float: none;
      width: 100%;

      &:last-child {
        padding-left: 0;
      }
    }
  }

  .input_row {
    &.invalid {
      .checkbox_group {
        margin-bottom: 4px;
      }

      input[type="checkbox"] {
        border-color: ${styles.error};
      }
    }
  }

  .error_message {
    display: block;
    margin-left: 120px;
    font-size: 11px;
    color: ${styles.error};
  }
`;

export { StyledAccountOrganizationFormDetails as AccountOrganizationFormDetails };
