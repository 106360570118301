export const LIMITED_CITIES = [
  'Long Island',
  'New York City',
  'Westchester'
];

export const LIMITED_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const LIMITED_COUNTRIES = [
  'United States'
];

export const GROUP_TYPES = [
  'Day Camp',
  'Overnight Camp',
  'Summer Program',
  'Community Center',
  'Synagogue',
  'Day School',
  'Jewish Supplementary School',
  'Youth Group',
  'Other'
];

export const DENOMINATION_TYPES = [
  'Conservative',
  'Non-denominational',
  'Orthodox',
  'Pluralistic',
  'Reform',
  'Reconstructionist',
  'Renewal',
  'Secular',
  'Other'
];

export const ORTHODOX_TYPES = [
  'Centrist Orthodox',
  'Chabad',
  'Charedi',
  'Chassidishe',
  'Modern Orthodox',
  'Open Orthodox',
  'Other'
];
