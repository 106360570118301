import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  Input
} from '../components';


const SetupTeamMemberItem = ({ className, member, numberOfMembers, index, onInputChange, onRemoveTeamMember }) => {
  const { firstName, lastName, title, email, validFirstName, validLastName, validEmail, valid, errorMessage } = member;

  const handleInputChange = (name, value) => {
    onInputChange(name, value, index);
  };

  return (
    <div className={`${className} float_clear`}>
      <Input
        name="firstName"
        placeholder="First Name"
        onChange={handleInputChange}
        value={firstName}
        shouldSetError={!validFirstName}
        errorMessage="Enter a first name."
      />
      <Input
        name="lastName"
        placeholder="Last Name"
        onChange={handleInputChange}
        value={lastName}
        shouldSetError={!validLastName}
        errorMessage="Enter a last name."
      />
      <Input
        name="title"
        placeholder="Title"
        onChange={handleInputChange}
        value={title}
      />
      <Input
        name="email"
        placeholder="Email"
        onChange={handleInputChange}
        value={email}
        shouldSetError={!valid || !validEmail}
        errorMessage={errorMessage}
      />
      {numberOfMembers > 1 && (
        <i
          className="remove_member fa fa-trash-o"
          aria-hidden="true"
          onClick={() => onRemoveTeamMember(index)}
        />
      )}
    </div>
  );
};


const StyledSetupTeamMemberItem = styled(SetupTeamMemberItem)`
  position: relative;

  .input {
    float: left;
    width: 25%;
    position: relative;

    input {
      border-radius: 0;
      margin-bottom: 20px;

      &.error {
        margin-bottom: 0;
      }
    }

    &:first-child {
      input {
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      input {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .remove_member {
    position: absolute;
    top: 13px;
    right: -25px;
    cursor: pointer;
    color: ${styles.textGrayed};
    transition: color 50ms;

    &:hover {
      color: ${styles.error};
      transition: color 300ms;
    }
  }
`;

export { StyledSetupTeamMemberItem as SetupTeamMemberItem };
