import React from 'react';
import styled from 'styled-components';

import {
  SectionTitle,
  Card,
  CardContent
} from '../components';

class DashboardResources extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <ul className={`${className} float_clear`}>
        <li>
          <SectionTitle title="My Team" />
          <Card>
            <CardContent />
          </Card>
        </li>
        <li>
          <SectionTitle title="Articles" />
          <Card>
            <CardContent />
          </Card>
        </li>
        <li>
          <SectionTitle title="Community" />
          <Card>
            <CardContent />
          </Card>
        </li>
      </ul>
    );
  }
}

const StyledDashboardResources = styled(DashboardResources)`
  & > li {
    float: left;
    width: 33.3333%;

    &:nth-child(3n-1) {
      padding: 0 20px;
    }

    &:nth-child(3n-2) {
      padding: 0 40px 0 0;
    }

    &:nth-child(3n-3) {
      padding: 0 0 0 40px;
    }
  }
`;

export { StyledDashboardResources as DashboardResources };
