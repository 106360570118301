import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';

import {
  // LogInModal,
  LogInForm,
  LogInPwReset,
  LogInPwCreate,
  Logo,
  Loader,
  ModalDialog,
  withData
} from '../components';

import { GET, getCookie } from '../../libs';


class LogIn extends React.Component {
  state = {
    showLogin: false,
    showForm: 'login',
    loading: false,
    resetAction: null,
    resetKey: null,
    resetLogin: null,
    showResetConfirmation: false,
    showAccessErrorModal: false,
    accessModalContent: ''
  };

  componentDidMount() {
    const { location } = this.props;
    let action, key, login;
    const email = getCookie('auth_email');
    const token = getCookie('auth_token');

    if (location.state) {
      action = location.state.action;
      key = location.state.key;
      login = location.state.login;
    }

    if (action && action === 'rp' && key && login) {
      this.setState({
        showLogin: true,
        showForm: 'create',
        resetAction: action,
        resetKey: key,
        resetLogin: login
      }, () => console.log(this.state));
    } else if (!email || !token) {
      this.setState({ showLogin: true });
    } else {
      this.logIn(email);
    }
  }

  logIn = email => {
    const { removeCookies, setUser, loadPractices, loadTeamMembers, loadAppData, history } = this.props;

    this.setState({ loading: true, showLogin: false });

    GET.user(email)
      .then(res => {
        const userData = res.data;
        if (userData) {
          const { id, is_active, is_creator, has_completed_org_setup } = userData;
          // console.log('userData', userData);

          if (!is_active) {
            this.setState({
              showAccessErrorModal: true,
              accessModalContent: 'not_active',
              loading: false,
              showLogin: true
            });

          } else if (is_creator && !has_completed_org_setup) {
            window.location.href =
              `${process.env.REACT_APP_API_BASE}/registration-success/?sub_id=${id}&login=true`;

          } else if (!is_creator && !has_completed_org_setup) {
            this.setState({
              showAccessErrorModal: true,
              accessModalContent: 'org_setup',
              loading: false,
              showLogin: true
            });

          } else {
            setUser(userData);

            Promise.all([
              loadAppData(),
              loadPractices(),
              loadTeamMembers()
            ])
              .then(() => {
                this.setState({ loading: false }, () => {
                  history.push('/');
                });
              });
          }
        } else {
          removeCookies();
          window.location.href = process.env.REACT_APP_API_BASE;
        }
      });
  };

  showResetPw = () => {
    this.setState({ showForm: 'reset' });
  }

  showCreatePw = () => {
    this.setState({ showForm: 'create' });
  };

  showLoginForm = showResetConfirmation => {
    const newState = { showForm: 'login' };

    if (showResetConfirmation) newState.showResetConfirmation = true;
    this.setState(newState);
  };

  handleToggleModal = () => {
    this.setState(prev => ({ showAccessErrorModal: !prev.showAccessErrorModal }));
  };

  getAccessModalErrorContent = () => {
    const { accessModalContent } = this.state;

    switch (accessModalContent) {
      case 'not_active':
        return (
          <p>
            Your subscription is no longer active. Please <a href="https://www.aleinucampaign.org/registration">renew your membership</a> or contact <a href="mailto:Aleinu@JewishSacredSpaces.org">Aleinu@JewishSacredSpaces.org</a> if you have any questions.
          </p>
        );
      case 'org_setup':
        return <p>{'Your organization\'s admin must complete setup before you can log in.'}</p>;
      default:
        return '';
    }
  };

  render() {
    const { className } = this.props;
    const {
      showLogin,
      showForm,
      loading,
      resetKey,
      resetLogin,
      showResetConfirmation,
      showAccessErrorModal
    } = this.state;

    return (
      <div className={className}>
        {showLogin ? (
          <div className="login_container">
            <Logo />
            {showForm === 'reset' && (
              <LogInPwReset
                showLoginForm={this.showLoginForm}
              />
            )}
            {showForm === 'create' && (
              <LogInPwCreate
                resetKey={resetKey}
                resetLogin={resetLogin}
                showLoginForm={this.showLoginForm}
                showResetPw={this.showResetPw}
              />
            )}
            {showForm === 'login' && (
              <LogInForm
                logIn={this.logIn}
                showResetPw={this.showResetPw}
                showResetConfirmation={showResetConfirmation}
              />
            )}
          </div>
        ) : (
          <div className="loading_container">
            <Logo />
            <Loader
              className={loading ? 'loading' : ''}
              size={23}
            />
          </div>
        )}
        <ModalDialog
          show={showAccessErrorModal}
          toggle={this.handleToggleModal}
        >{this.getAccessModalErrorContent()}
        </ModalDialog>
      </div>
    );
  }
}

const StyledLogIn = styled(LogIn)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 600;
  background-color: ${styles.pageBg};

  .login_container {
    width: 100%;
    max-width: 500px;

    .logo {
      width: 220px;
      margin-bottom: 20px;
    }
  }

  .loading_container {
    .logo {
      width: 230px;
      margin-bottom: 5px;
    }

    .loader {
      opacity: 0;

      &.loading {
        opacity: 1;
      }
    }
  }

  .loading_container,
  .login_container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

const LogInWithData = withData(StyledLogIn);
const LogInWithRouter = withRouter(LogInWithData);
export { LogInWithRouter as LogIn };
