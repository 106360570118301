import Book from './Book';
import Calendar from './Calendar';
import Checklist from './Checklist';
import Checkmark from './Checkmark';
import Child from './Child';
import Clipboard from './Clipboard';
import Clock from './Clock';
import Committee from './Committee';
import Computer from './Computer';
import Document from './Document';
import Door from './Door';
import Globe from './Globe';
import Lesson from './Lesson';
import Organizations from './Organizations';
import Podium from './Podium';
import ResourcesKids from './ResourcesKids';
import ResourcesParent from './ResourcesParent';
import Resume from './Resume';
import Sharing from './Sharing';
import SpeachBubbles from './SpeachBubbles';
import Spreadsheet from './Spreadsheet';
import Support from './Support';
import Training from './Training';
import Video from './Video';
import Faqs from './Faqs';
import Sample from './Sample';
import Worksheet from './Worksheet';

export default {
  'book': Book,
  'guide': Book,
  'calendar': Calendar,
  'checklist': Checklist,
  'checkmark': Checkmark,
  'child': Child,
  'clipboard': Clipboard,
  'clock': Clock,
  'committee': Committee,
  'computer': Computer,
  'document': Document,
  'door': Door,
  'globe': Globe,
  'lesson': Lesson,
  'organizations': Organizations,
  'podium': Podium,
  'resources-kids': ResourcesKids,
  'resources-parent': ResourcesParent,
  'resume': Resume,
  'sharing': Sharing,
  'speach-bubbles': SpeachBubbles,
  'spreadsheet': Spreadsheet,
  'support': Support,
  'training': Training,
  'video': Video,
  'form': Checklist,
  'survey': Checklist,
  'post_survey': Checklist,
  'worksheet': Worksheet,
  'sample': Sample,
  'faqs': Faqs
};
