import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Loader,
  BestPracticesList,
  SectionTitle,
  DashboardTooltipContainer,
  withData
} from '../components';


import { getFirstStartDate, allPracticesArePending } from '../../libs';

class DashboardProgress extends React.Component {
  state = {
    practices: [],
    hasLoaded: false,
    tooltip: null
  };

  bPList = React.createRef();

  componentDidMount() {
    const { practices, getUser, loadPractices } = this.props;
    const user = getUser();

    if (practices) {
      this.setState({
        practices,
        hasLoaded: true,
        tooltip: !user.tooltip_start ? 'pending' : (!user.tooltip_progress ? 'in_progress' : null)
      });
    } else {
      loadPractices();
    }
  }

  componentWillReceiveProps(newProps) {
    const { getUser } = this.props;
    const { practices } = this.state;
    const user = getUser();

    if (newProps.practices && practices !== newProps.practices) {
      this.setState({
        practices: newProps.practices,
        hasLoaded: true,
        tooltip: !user.tooltip_start ? 'pending' : (!user.tooltip_progress ? 'in_progress' : null)
      });
    }
  }

  getProgressLabel = () => {
    const { practices } = this.props;

    if (!allPracticesArePending(practices)) {
      return (
        <p>Here's your current progress<i className="pipe" />Started {getFirstStartDate(practices)}</p>
      );
    }

    // Message for before starting any practices? //
    return null;
  };

  handleUpdateTooltipStatus = tooltip => {
    this.setState({ tooltip });
  }

  render() {
    const { practices, hasLoaded, tooltip } = this.state;
    const { className, location } = this.props;
    const bPList = this.bPList.current;
    const isDashboard = location.pathname === '/' || location.pathname === '';

    return (
      <div className={`${className} float_clear`}>
        <SectionTitle>
          <h2>My Progress</h2>
          {hasLoaded ? (
            this.getProgressLabel()
          ) : (
            <Loader />
          )}
          <ul>
            <li><i className="key_color primary" />Completed</li>
            <li><i className="key_color secondary" />In Progress</li>
          </ul>
        </SectionTitle>
        <div ref={this.bPList}>
          {practices && practices.length > 0 && <BestPracticesList practices={practices} />}
        </div>
        {tooltip !== null && bPList && isDashboard && (
          <DashboardTooltipContainer
            bPList={bPList}
            tooltip={tooltip}
            practices={practices}
            updateTooltipStatus={this.handleUpdateTooltipStatus}
          />
        )}
      </div>
    );
  }
}

const StyledDashboardProgress = styled(DashboardProgress)`
  .loader {
    margin-left: 15px;
    transform: translateY(-2px);
  }
`;

const DashboardProgressWithData = withData(StyledDashboardProgress);
const DashboardProgressWithRouter = withRouter(DashboardProgressWithData);
export { DashboardProgressWithRouter as DashboardProgress };
