import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Button, DropDownMenu } from '../components';

import { isSurvey, getToolTypeLabel } from '../../libs';


const BestPracticePageMediaButton = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const {
    className,
    media,
    buttonStyle,
    handleShowVideo,
    handleShowDocument,
    handleToggleEmbed,
    embedIsOpen
  } = props;

  let buttonText = '';
  let buttonAction = null;
  let buttonLink = null;
  let buttonType = 'single';

  // Embedded Content //
  if (isSurvey(media) || media.document_type === 'embedded') {
    buttonText = `${embedIsOpen ? `Hide` : `View`} ${getToolTypeLabel(media)}`;
    buttonAction = handleToggleEmbed;

  // Video Content //
  } else if (media.type === 'video') {
    buttonText = 'Play Video';

    if (media.videos && media.videos.length > 1) {
      buttonAction = toggleIsOpen;
      buttonType = 'multi';
      buttonText += 's';
    } else {
      buttonAction = () => handleShowVideo(media.videos[0] || null);
    }

  // Downloadable Content //
  } else {
    buttonText = `View ${getToolTypeLabel(media)}${media.documents && media.documents.length > 1 ? 's' : ''}`;
    buttonAction = toggleIsOpen;
    buttonType = 'multi';
  }

  // Single File //
  if (buttonType === 'single') {
    return (
      <Button
        buttonStyle={buttonStyle}
        handleClick={buttonAction}
        link={buttonLink}
      >
        {buttonText}
      </Button>
    );

  // Multiple Files with Dropdown //
  } else {
    const options = media.type === 'video' ? media.videos : media.documents;
    const defaultName = getToolTypeLabel(media);
    const classNames = [className];
    if (media.type !== 'video') classNames.push('document');

    return (
      <div className={classNames.join(' ')}>
        <Button
          buttonStyle={buttonStyle}
          handleClick={buttonAction}
        >
          {buttonText}
        </Button>
        {isOpen && options && (
          <DropDownMenu
            toggle={toggleIsOpen}
            onListClick={toggleIsOpen}
            backdrop
          >
            {options.map((option, i) => (
              <li key={i}>
                {media.type === 'video' ? (
                  <span onClick={() => handleShowVideo(option)}>
                    {option.name ? option.name : defaultName}
                  </span>
                ) : (
                  <span className="float_clear">
                    {option.name ? option.name : defaultName}
                    <span className="actions">
                      <span className="view" onClick={() => handleShowDocument(option.file)}>View</span>
                      <span className="line">|</span>
                      <a href={option.file} className="download" rel="noopener noreferrer" target="_blank">
                        Download
                      </a>
                    </span>
                  </span>
                )}
              </li>
            ))}
          </DropDownMenu>
        )}
      </div>
    );
  }
}

const StyledBestPracticePageMediaButton = styled(BestPracticePageMediaButton)`
  display: inline-block;
  position: relative;

  & > div {
    position: absolute;
    top: 100%;
    background-color: #fff;
  }

  &.document {
    .dropdown_menu {
      width: auto;

      & > li {
        &:hover {
          background: #fff;

          & > span {
            color: #333;
          }
        }

        & > span {
          cursor: default;
          margin: 0;
          padding-right: 150px;
          position: relative;

          .actions {
            position: absolute;
            right: 20px;
          }

          .view {
            color: ${styles.link};
            cursor: pointer;
            transition: color 250ms;

            &:hover {
              color: ${styles.secondary};
            }
          }

          .line {
            display: inline-block;
            margin: 0 7px;
            color: #eee;
          }

          a {
            &:hover {
              color: ${styles.secondary};
            }
          }
        }
      }
    }
  }
  
  .dropdown_menu {
    & > li {
      & > span {
        margin-right: 0;
      }
    }
  }
`;

export { StyledBestPracticePageMediaButton as BestPracticePageMediaButton };
