import React from 'react';
import { Context } from '../components';

const withData = Component => props => (
  <Context.Consumer>
    {consumerProps => {
      return (
        <Component
          {...consumerProps}
          {...props}
        />
      );
    }}
  </Context.Consumer>
);

export { withData };
