import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Modal, Button } from '../components';


const TeamDeleteUserModal = ({ className, show, toggle, member, handleRemoveTeamMember }) => (
  <Modal
    className={className}
    size="sm"
    show={show}
    toggle={toggle}
  >
    <p>
      Are you sure you want to remove <br />
      <span>{member.first_name} {member.last_name}</span> from your team?
    </p>
    <div className="float_clear">
      <Button
        buttonStyle="primary outline"
        className="float_left"
        handleClick={toggle}
      >Cancel</Button>
      <Button
        buttonStyle="destroy"
        className="float_right"
        handleClick={() => handleRemoveTeamMember(member)}
      >Remove</Button>
    </div>
  </Modal>
);


const StyledTeamDeleteUserModal = styled(TeamDeleteUserModal)`
  .modal-body {
    padding: 40px 30px 30px;
  }

  p {
    margin-bottom: 35px;
    text-align: center;

    span {
      color: ${styles.heading};
      font-family: ${styles.nationalRegular};
    }
  }
`;

export { StyledTeamDeleteUserModal as TeamDeleteUserModal };
