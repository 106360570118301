const getCookie = type => {
  const cookie = document.cookie.split(';').filter(item => item.trim().startsWith(type + '='));
  if (cookie.length === 0) return null;
  else return decodeURIComponent(cookie[0].split('=')[1].trim());
};

const setCookie = (value, type) => {
  const expDate = new Date();
  expDate.setDate(expDate.getDate() + 120);
  document.cookie = `${type}=${encodeURIComponent(value)};path=/;expires=${expDate.toUTCString()}`;
};

const deleteCookies = () => {
  document.cookie = 'auth_token=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  document.cookie = 'auth_email=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export {
  getCookie,
  setCookie,
  deleteCookies
};
