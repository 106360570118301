import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  Icon,
  BestPracticePageActionButtons,
  BestPracticePageSurvey,
  BestPracticePageSurveyModal,
  BestPracticePageFeedbackModal,
  StatusBadge,
  withData
} from '../components';

import {
  POST,
  pluralize,
  allResourcesAreComplete,
  allPhasesAreComplete,
  isEmbedded,
  isSurvey,
  getPreSurvey,
  getToolTypeLabel,
  shouldShowConditionalSurveyQuestion
} from '../../libs';


class BestPracticePageResource extends React.Component {
  state = {
    isOpen: false,
    embedIsOpen: false,
    status: 'pending',
    surveyQuestions: [],
    showSurveyModal: false,
    showFeedbackModal: false,
    loading: false
  };

  componentDidMount() {
    const { practice, resource: { status, media: { type, survey_questions, survey_type } } } = this.props;
    const newState = { status, surveyQuestions: [] };

    if (type === 'survey' && Array.isArray(survey_questions)) {
      const surveyQuestions = (survey_type === 'post')
        ? getPreSurvey(practice)
        : [];

      newState.surveyQuestions = surveyQuestions.concat(survey_questions.map(q => ({
        id: q.id,
        question: q.question,
        answer_type: q.type.answer_type,
        choices: q.type.choices,
        selections: q.type.selections,
        includeOther: q.type.include_other,
        required: q.type.required,
        conditional: q.conditional,
        answer: q.type.answer_type === 'dropdown' && q.type.selections && q.type.selections.length ? q.type.selections[0].text : '',
        type: survey_type
      })));
    }

    this.setState(newState);
  }

  surveyIsComplete = () => {
    const { resource: { media } } = this.props;
    const { surveyQuestions } = this.state;

    let isComplete = true;

    if (isSurvey(media) && surveyQuestions.length) {
      surveyQuestions.forEach(q => {
        if (isSurvey(media) && (!q.required || !shouldShowConditionalSurveyQuestion(q, surveyQuestions))) return;
        if (q.answer === '') isComplete = false;
      });

      return isComplete;
    }

    return false;
  };

  handleToggleOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleToggleEmbed = () => {
    this.setState(prevState => ({ embedIsOpen: !prevState.embedIsOpen }));
  };

  handleStartStep = () => {
    const {
      updateResourceStatus,
      updatePracticeStatus,
      updatePhaseStatus,
      updateCurrentPhase,
      practice,
      phase,
      resource,
      // phaseIndex,
      canStartPractice,
      canStartPhase,
      getUser
    } = this.props;

    const user = getUser();

    if (canStartPractice() && canStartPhase(resource)) {
      this.setState({ status: 'in_progress' }, () => {
        updateResourceStatus('in_progress', practice.id, phase.id, resource.id);
        updatePhaseStatus('in_progress', practice.id, phase.id);
        updatePracticeStatus('in_progress', practice.id);
        updateCurrentPhase(phase.title, practice.id);

        window.dataLayer && window.dataLayer.push({
          event: 'bp-tool-start',
          organizationName: user && user.group_name,
          bestPractice: practice.title,
          toolName: resource.title,
          timestamp: (new Date()).toString()
        });
      });
    }
  };

  handleCompleteStep = () => {
    const {
      updateResourceStatus,
      updatePhaseStatus,
      updatePracticeStatus,
      practice,
      phase,
      resource,
      getUser
    } = this.props;

    const user = getUser();

    this.setState({ status: 'complete', showFeedbackModal: true }, () => {
      updateResourceStatus('complete', practice.id, phase.id, resource.id);

      if (allResourcesAreComplete(phase)) {
        updatePhaseStatus('complete', practice.id, phase.id);
      }

      if (allPhasesAreComplete(practice)) {
        updatePracticeStatus('complete', practice.id);
      }

      window.dataLayer && window.dataLayer.push({
        event: 'bp-tool-complete',
        organizationName: user && user.group_name,
        bestPractice: practice.title,
        toolName: resource.title,
        timestamp: (new Date()).toString()
      });
    });
  };

  handleAnswerQuestion = (id, value) => {
    const { surveyQuestions } = this.state;

    const newSurveyQuestions = surveyQuestions.map(question => {
      const q = Object.assign({}, question);
      if (q.id === id) q.answer = value;
      return q;
    });

    this.setState({ surveyQuestions: newSurveyQuestions });
  };

  handleShowCompleteSurveyModal = () => {
    this.setState({ showSurveyModal: true });
  };

  handleToggleCompleteSurveyModal = () => {
    this.setState(prevState => ({ showSurveyModal: !prevState.showSurveyModal }));
  };

  handleCompleteSurvey = () => {
    const { getGroupId, practice, phase, resource } = this.props;
    const { surveyQuestions } = this.state;
    const { media: { survey_type } } = resource;
    const groupId = getGroupId();

    this.setState({ loading: true });

    POST.submitSurvey(groupId, practice.id, phase.id, resource.id, surveyQuestions, survey_type)
      .then(resp => {
        this.handleCompleteStep();
        this.handleToggleCompleteSurveyModal();
        this.setState({ loading: false, embedIsOpen: false });
      });
  };

  handleMarkAsInProgress = () => {
    const {
      updateResourceStatus,
      updatePhaseStatus,
      updatePracticeStatus,
      practice,
      phase,
      resource,
      getUser
    } = this.props;

    const user = getUser();

    this.setState({ status: 'in_progress' }, () => {
      updateResourceStatus('in_progress', practice.id, phase.id, resource.id);
      updatePhaseStatus('in_progress', practice.id, phase.id);
      updatePracticeStatus('in_progress', practice.id);

      window.dataLayer && window.dataLayer.push({
        event: 'bp-tool-in-progress',
        organizationName: user && user.group_name,
        bestPractice: practice.title,
        toolName: resource.title,
        timestamp: (new Date()).toString()
      });
    });
  };

  handleToggleFeedbackModal = () => {
    this.setState(prevState => ({ showFeedbackModal: !prevState.showFeedbackModal }));
  };

  handleSubmitFeedback = (rating, feedback) => {
    const { getGroupId, practice, phase, resource } = this.props;
    const groupId = getGroupId();

    this.setState({ loading: true });

    POST.submitFeedback(groupId, practice.id, phase.id, resource.id, rating, feedback)
      .then(resp => this.setState({ loading: false }));
  };

  getResourceType = () => {
    const { resource: { media } } = this.props;
    const { isOpen } = this.state;
    const type = getToolTypeLabel(media);

    return (
      <span className="type">
        <Icon name={media.type} color={isOpen ? styles.textDark : styles.textGrayed} />
        {type}
      </span>
    )
  };

  getResourceDuration = () => {
    const { resource: { details: { duration } } } = this.props;
    const timeSpan = duration.time_span === 'minutes' ? 'mins' : duration.time_span;
    return pluralize(duration.value, timeSpan);
  };

  render() {
    const { className, resource, handleShowVideo, handleShowDocument } = this.props;
    const { isOpen, embedIsOpen, status, surveyQuestions, showSurveyModal, showFeedbackModal, loading } = this.state;
    const { id, title, details, media } = resource;

    return (
      <li className={`${className} ${isOpen ? 'open' : ''}`}>
        <div className="labels float_clear" onClick={this.handleToggleOpen}>
          <div className="label check"><StatusBadge size="small" status={status} /></div>
          <div className="label title">{title}</div>
          <div className="label type">{this.getResourceType()}</div>
          <div className="label duration">{this.getResourceDuration()}</div>
        </div>
        {isOpen && (
          <div className="details">
            {details.requirements && (
              <div className="requirements" dangerouslySetInnerHTML={{ __html: details.requirements }} />
            )}
            {isEmbedded(media) && embedIsOpen && (
              <div
                className="embedded_document"
                dangerouslySetInnerHTML={{ __html: media.embedded_document }}
              />
            )}
            {isSurvey(media) && embedIsOpen && (
              <div className="embedded_document">
                <BestPracticePageSurvey
                  questions={surveyQuestions}
                  handleAnswerQuestion={this.handleAnswerQuestion}
                />
              </div>
            )}
            <BestPracticePageActionButtons
              resourceId={id}
              status={status}
              media={media}
              handleStartStep={this.handleStartStep}
              handleCompleteStep={this.handleCompleteStep}
              handleCompleteSurvey={this.handleShowCompleteSurveyModal}
              handleMarkAsInProgress={this.handleMarkAsInProgress}
              handleShowVideo={handleShowVideo}
              handleShowDocument={handleShowDocument}
              handleToggleEmbed={this.handleToggleEmbed}
              embedIsOpen={embedIsOpen}
              surveyIsComplete={this.surveyIsComplete()}
              handleShowFeedbackModal={() => this.setState({ showFeedbackModal: true })}
            />
          </div>
        )}
        <BestPracticePageSurveyModal
          show={showSurveyModal}
          toggle={this.handleToggleCompleteSurveyModal}
          handleSubmitSurvey={this.handleCompleteSurvey}
          media={media}
          loading={loading}
        />
        <BestPracticePageFeedbackModal
          show={showFeedbackModal}
          toggle={this.handleToggleFeedbackModal}
          resource={resource}
          handleSubmitFeedback={this.handleSubmitFeedback}
          loading={loading}
        />
      </li>
    );
  }
}


const StyledBestPracticePageResource = styled(BestPracticePageResource)`
  border: 1px solid ${styles.borderLight};
  border-radius: 5px;
  margin-bottom: 15px;
  transition: box-shadow 300ms, border-color 300ms;

  &:hover,
  &.open {
    box-shadow: 0px 5px 15px 0px rgba(45, 47, 56, 0.05);
    border-color: ${styles.border};

    .label {
      color: ${styles.textDark};
    }

    svg {
      .line {
        fill: ${styles.textDark};
      }

      .stroke {
        stroke: ${styles.textDark};
      }
    }
  }

  .labels {
    padding: 20px;
    cursor: pointer;
  }

  .status_badge {

  }

  .label {
    float: left;
    font-size: 17px;
    font-family: ${styles.nationalRegular};
    color: ${styles.textGrayed};
    transition: color 300ms;

    &.check {
      width: 38px;
      padding-top: 4px;
    }

    &.title {
      width: calc(100% - 198px);
    }

    &.type,
    &.duration {
      width: 80px;
      font-size: 14px;
      padding-top: 2px;
      font-family: ${styles.nationalBook};
    }

    &.duration {
      text-align: right;
    }

    .type {
      display: block;
      text-transform: capitalize;
      position: relative;
      padding-left: 25px;

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 18px;

        .clipboard,
        .spreadsheet,
        .checklist {
          width: 13px;
          left: 1px;
        }

        .faq {
          width: 14px;
          left: 1px;
        }

        .worksheet {
          width: 17px;
        }
      }
    }
  }

  .details {
    padding: 0 20px 25px 58px;

    .requirements {
      margin-bottom: 25px;

      h4 {
        margin: 15px 0 7px;
        color: ${styles.heading};
        font-size: 14px;
        font-family: ${styles.nationalBold};

        &:first-child {
          margin-top: 0;
        }
      }

      p {
        margin: 0 0 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }

    p,
    li {
      line-height: 1.35;
      color: ${styles.headingLight};
      font-size: 15px;
      letter-spacing: 0.02em;
    }
  }

  .embedded_document {
    margin: 30px 0;
    padding: 30px 0 10px;
    border-top: 1px solid #e3e5ee;
    border-bottom: 1px solid #e3e5ee;

    p {
      margin-bottom: 20px;
    }

    ul {
      padding-left: 25px;
      margin-bottom: 20px;
      list-style-type: disc;
    }
  }
`;

const BestPracticePageResourceWithData = withData(StyledBestPracticePageResource);

export { BestPracticePageResourceWithData as BestPracticePageResource };
