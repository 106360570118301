import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/app.scss';
import styles from '../../styles';

import {
  AuthRoute,
  SideMenu,
  Dashboard,
  BestPracticePage,
  // Resources,
  // Community,
  Account,
  AccountPasswordUpdate,
  AccountManageOrganization,
  Team,
  TeamAddMembers,
  Glossary,
  Participants,
  Setup,
  LogIn
} from '../components';


const App = ({ className }) => (
  <div className={className}>
    <Router>
      <Fragment>
        <SideMenu />
        <Switch>
          <AuthRoute path="/" exact component={Dashboard} />
          <AuthRoute path="/practice/:id" component={BestPracticePage} />
          <AuthRoute path="/account" exact component={Account} />
          <AuthRoute path="/account/organization" exact component={AccountManageOrganization} />
          <AuthRoute path="/account/password" exact component={AccountPasswordUpdate} />
          <AuthRoute path="/team" exact component={Team} />
          <AuthRoute path="/team/add" exact component={TeamAddMembers} />
          <AuthRoute path="/glossary" exact component={Glossary} />
          <AuthRoute path="/participants" exact component={Participants} />
          <Route path="/setup/:group_uuid" component={Setup} />
          <Route path="/login" exact component={LogIn} />
          <Redirect to="/" />
        </Switch>
      </Fragment>
    </Router>
  </div>
);


const pagePadding = [
  `${styles.pagePaddingTop}px`,
  `${styles.pagePaddingSide}px`,
  `${styles.pagePaddingTop}px`,
  `${parseInt(styles.menuWidth) + parseInt(styles.pagePaddingSide)}px`
];

const StyledApp = styled(App)`
  min-height: 100vh;
  padding: ${pagePadding.join(' ')};
  background-color: ${styles.pageBg};
`;

export { StyledApp as App };
