import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Header,
  PageTabs,
  TeamMembersTable,
  TeamEditMemberModal,
  TeamDeleteUserModal,
  ModalDialog,
  withData
} from '../components';

const pageTabs = [
  { url: '/team', label: 'Manage Team' },
  { url: '/team/add', label: 'Add Team Members', adminOnly: true }
];


class Team extends React.Component {
  state = {
    teamMembers: null,
    showEditMemberModal: false,
    showDeleteMemberModal: false,
    showUpdateConfirmationModal: false,
    memberToEdit: null,
    memberToDelete: null
  };

  componentDidMount() {
    const { getTeamMembers } = this.props;
    const teamMembers = getTeamMembers();
    this.setState({ teamMembers });
  }

  handleOpenEditMember = memberToEdit => {
    this.setState({ memberToEdit, showEditMemberModal: true });
  };

  handlOpenDeleteMember = memberToDelete => {
    this.setState({ memberToDelete, showDeleteMemberModal: true });
  };

  toggleEditMemberModal = () => {
    this.setState(prevState => ({ showEditMemberModal: !prevState.showEditMemberModal }));
  };

  toggleDeleteMemberModal = () => {
    this.setState(prevState => ({ showDeleteMemberModal: !prevState.showDeleteMemberModal }));
  }

  toggleUpdateConfirmationModal = () => {
    this.setState(prevState => ({ showUpdateConfirmationModal: !prevState.showUpdateConfirmationModal }));
  }

  handleRemoveTeamMember = user => {
    const { deleteUser } = this.props;
    const { teamMembers } = this.state;
    const newTeamMembers = teamMembers.filter(member => member.id !== user.id);

    deleteUser(user);
    this.setState({ showDeleteMemberModal: false, teamMembers: newTeamMembers });
  };

  handleShowConfirmation = () => {
    this.setState({ showEditMemberModal: false, showUpdateConfirmationModal: true });
  }

  render() {
    const { className, getUser } = this.props;
    const {
      teamMembers,
      showEditMemberModal,
      showDeleteMemberModal,
      showUpdateConfirmationModal,
      memberToEdit,
      memberToDelete
    } = this.state;
    const user = getUser();
    const isAdmin = user.role === 'admin';

    return (
      <div className={className}>
        <Header title="My Team" />
        <PageTabs tabs={pageTabs} />
        <TeamMembersTable
          teamMembers={teamMembers}
          user={user}
          isAdmin={isAdmin}
          handleOpenEditMember={this.handleOpenEditMember}
          handleOpenDeleteMember={this.handlOpenDeleteMember}
        />
        {memberToEdit && (
          <Fragment>
            <TeamEditMemberModal
              teamMembers={teamMembers}
              member={memberToEdit}
              showConfirmation={this.handleShowConfirmation}
              show={showEditMemberModal}
              toggle={this.toggleEditMemberModal}
            />
            <ModalDialog
              show={showUpdateConfirmationModal}
              toggle={this.toggleUpdateConfirmationModal}
              text={`${memberToEdit.first_name} ${memberToEdit.last_name} has been updated.`}
            />
          </Fragment>
        )}
        {memberToDelete && (
          <TeamDeleteUserModal
            member={memberToDelete}
            show={showDeleteMemberModal}
            toggle={this.toggleDeleteMemberModal}
            handleRemoveTeamMember={this.handleRemoveTeamMember}
          />
        )}
      </div>
    );
  }
}


const StyledTeam = styled(Team)`

`;

const TeamWithData = withData(StyledTeam);
export { TeamWithData as Team };
