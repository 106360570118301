import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Loader } from '../components';


const TextField = props => {
  const [hasError, setError] = useState(false);

  const {
    className,
    inputClassName,
    children,
    fieldType = 'input',
    type = 'text',
    name,
    placeholder,
    value,
    onChange,
    onValidate,
    errorMessage = 'This field is required.',
    shouldSetError,
    required,
    loading,
    disabled,
    rows
  } = props;

  const checkValidity = useCallback(() => {
    if (value !== '') {
      setError(false);
      onValidate && onValidate(name, true);
    } else {
      setError(true);
      onValidate && onValidate(name, false);
    }
  }, [name, value, onValidate]);

  useEffect(() => {
    if (!hasError && shouldSetError) {
      setError(true);
      onValidate && onValidate(name, false);
    }
    if (hasError && shouldSetError === false) checkValidity();
  }, [hasError, shouldSetError, value, checkValidity, onValidate, name]);

  const inputClasses = [];
  const classNames = [fieldType, className];
  fieldType === 'textarea' && hasError && classNames.push('error');
  fieldType === 'input' && hasError && inputClasses.push('error');
  inputClassName && inputClasses.push(inputClassName);

  const vars = {
    inputClasses: inputClasses.join(' ').trim(),
    type,
    name,
    placeholder,
    value: value || '',
    onChange: event => onChange(name, event.target.value),
    onBlur: () => required && checkValidity(),
    disabled,
    rows
  };

  return (
    <div className={classNames.join(' ').trim()}>
      {loading && <Loader color1="#fff" color2="#fff" color3="#fff" color4="#fff" />}
      {children(vars)}
      {hasError && <div className="input_error">{errorMessage}</div>}
    </div>
  );
}

const StyledTextField = styled(TextField)`
  position: relative;

  .loader {
    position: absolute;
    top: 10px;
    right: 20px;
  }
`;
export { StyledTextField as TextField };
