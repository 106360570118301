import React from 'react';
import styled from 'styled-components';
import styles  from '../../styles';

import { Modal, Button } from '../components';


const ModalDialog = ({ className, show, toggle, backdrop, text, children, noButton, buttonText = 'Ok' }) => (
  <Modal
    className={className}
    show={show}
    toggle={toggle}
    size="sm"
    backdrop={backdrop ? backdrop : true}
  >
    {text && (
      <p>{text}</p>
    )}
    {children || null}
    {!noButton && (
      <Button handleClick={toggle} size="sm">{buttonText}</Button>
    )}
  </Modal>
);


const StyledModalDialog = styled(ModalDialog)`
  text-align: center;

  p {
    margin-top: 5px;
    color: ${styles.headingLight};
    font-size: 17px;
  }

  a {
    color: ${styles.primary};

    &:hover {
      color: ${styles.primaryHover};
    }
  }

  .button {
    min-width: 120px;
    margin-top: 10px;
  }
`;

export { StyledModalDialog as ModalDialog };
