import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Icon } from '../components';

import { pluralize, percentComplete } from '../../libs';


const BestPracticePageHero = props => {
  const {
    className,
    practice: { hero_image, details, title, status /*, currentPhase*/ },
    handleCloseModal
  } = props;

  const getStatusBadge = () => {
    switch (status) {
      case 'complete':
        return 'Complete';
      case 'in_progress':
        return 'In Progress';
        // return <span>Phase<i className="pipe" />{currentPhase}</span>;
      default:
        return 'Not Started';
    }
  };

  return (
    <div className={className}>
      <i className="closer" onClick={handleCloseModal} />
      {hero_image && (
        <div
          className="hero_image"
          style={{ backgroundImage: `url(${hero_image})` }}
        />
      )}
      <div className="hero_content">
        <Icon name={details.icon} iconStyle="white" />
        <h2>
          {!details.getting_started_step && <span>{details.position}.</span>} {title}
        </h2>
        <p className="bp_progress">
          Takes about {pluralize(details.duration.value, details.duration.time_span)}
          <i className="pipe" />
          <span>{percentComplete(props.practice, 'practice')}% complete</span>
        </p>
      </div>
      <div className={`progress_label ${status}`}>
        {getStatusBadge()}
      </div>
    </div>
  );
};


const StyledBestPracticePageHero = styled(BestPracticePageHero)`
  position: relative;
  border-radius: 5px 5px 0 0;
  // background-color: #3a3a3a;
  background-color: ${styles.blue};


  .hero_image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    // opacity: 0.15;
    opacity: 0.4;
  }

  .hero_content {
    position: relative;
    z-index: 2;
    border-radius: 5px 5px 0 0;
    padding: 90px 20px 100px;
    // min-height: 500px;
    // background-color: rgba(${styles.burgandyRGB}, 0.45);
    background-color: rgba(${styles.blueRGB}, 0.7);
    color: #fff;
    text-align: center;

    .icon {
      height: 90px;
    }

    .pipe {
      height: 16px;
      margin: 0 15px;
      opacity: 0.3;
    }
  }

  h2 {
    font-size: 45px;
    font-weight: lighter;
    margin: 30px 0;
    font-family: ${styles.nationalLight};

    span {
      font-size: 24px;
    }
  }

  .bp_progress {
    span {
      font-family: ${styles.nationalMedium};
    }
  }

  .closer {
    z-index: 10;

    &::before,
    &::after {
      background-color: #fff;
    }

    &:hover {
      &::before,
      &::after {
        background-color: rgba(255,255,255,0.6);
      }
    }
  }

  .progress_label {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 3;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1;
    background-color: #abbecc;
    border: 1px solid #fff;
    border-radius: 17px;
    padding: 10px 18px;
    font-family: ${styles.nationalMedium};

    &.in_progress {
      padding: 7px 18px 10px;
      background-color: ${styles.secondary};

      .pipe {
        height: 14px;
        background-color: #fff;
        opacity: 0.7;
        margin: 0 12px;
      }
    }

    &.complete {
      background-color: ${styles.primary};
    }
  }
`;

export { StyledBestPracticePageHero as BestPracticePageHero };
