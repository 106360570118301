import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';

import {
  Card,
  Icon,
  StatusBadge
} from '../components';

import { pluralize, getCompletedOnDate, percentComplete } from '../../libs';


class BestPracticesCard extends React.Component {
  state = {
    isHovering: false
  };

  showHoverState = () => {
    this.setState({ isHovering: true });
  };

  hideHoverState = () => {
    this.setState({ isHovering: false });
  };

  handleCardClick = () => {
    const { practice: { id, title, release_date }, history, showRestrictionModal, isForTooltip } = this.props;

    if (isForTooltip) {
      return;
    } else if (release_date) {
      showRestrictionModal(title, release_date);
    } else {
      history.push(`/practice/${id}`);
    }
  };

  getIconColor = () => {
    const { practice: { status }, isForTooltip } = this.props;
    const { isHovering } = this.state;

    switch (status) {
      case 'in_progress':
        return 'secondary';
      case 'complete':
        return 'primary';
      default:
        if (isHovering || isForTooltip) return 'blue';
        return 'grayed';
    }
  };

  getStatusHeading = () => {
    const { practice: { status, details: { duration }, release_date } } = this.props;

    if (release_date) {
      return (
        <span className="status_heading pending">Available {release_date}</span>
      );
    }

    switch (status) {
      case 'in_progress':
        return (
          <span className="status_heading in_progress">
            <span>{this.getFirstIncompletePhase()}</span>
          </span>
        );
      case 'complete':
        return (
          <span className="status_heading complete">Complete</span>
        );
      default:
        return (
          <span className="status_heading pending">implement in {pluralize(duration.value, duration.time_span)}</span>
        );
    }
  };

  getCompletedOnLabel = () => {
    const { practice } = this.props;
    return (
      <span className="status_footer">completed {getCompletedOnDate(practice)}</span>
    );
  };

  getOtherOrgsLabel = () => {
    const { practice } = this.props;

    return (
      <span className="status_footer">
        {/* <span className="orgs">23 orgs</span> are working on this  */}
        {percentComplete(practice, 'practice')}% complete
      </span>
    );
  };

  getFirstIncompletePhase = () => {
    const { practice: { phases } } = this.props;
    let phaseName = '';

    for (const phase of phases) {
      if (phase.status !== 'complete') {
        phaseName = phase.title;
        break;
      }
    }

    return phaseName;
  };


  render() {
    const { className, practice, height, isForTooltip, position, dimensions } = this.props;
    const { isHovering } = this.state;
    const status = practice.status;
    const classNames = [
      'best_practices_card',
      className,
      status
    ];
    let cardStyle = { height };

    if (isHovering) classNames.push('hovered');
    if (isForTooltip) {
      classNames.push('for_tooltip');
      cardStyle = {
        height: dimensions.height,
        width: dimensions.width,
        top: position.top,
        left: position.left
      }
    }

    return (
      <div
        className={classNames.join(' ')}
        style={cardStyle}
      >
        <Card
          handleOnMouseEnter={this.showHoverState}
          handleOnMouseLeave={this.hideHoverState}
          handleOnClick={this.handleCardClick}
        >
          <div className="card_content">
            {status === 'complete' && <StatusBadge status="complete" />}
            {this.getStatusHeading()}
            <Icon
              name={practice.details.icon}
              iconStyle={this.getIconColor()}
            />
            <h3>
              {!practice.details.getting_started_step && <span>{practice.details.position}.</span>} {practice.title}
            </h3>
            {status === 'complete' && this.getCompletedOnLabel()}
            {status === 'in_progress' && this.getOtherOrgsLabel()}
          </div>
        </Card>
      </div>
    );
  }
}

const StyledBestPracticesCard = styled(BestPracticesCard)`
  float: left;
  text-align: center;

  &.pending {
    opacity: 0.7;
    transition: opacity 300ms;

    .card_content {
      opacity: 0.9;
      transition: opacity 300ms;

      h3 {
        color: ${styles.grayed};
      }
    }

    &.hovered,
    &.for_tooltip {
      opacity: 1;

      .card_content {
        opacity: 1;
      }

      .status_heading {
        &.pending {
          color: ${styles.heading};
        }
      }

      &.pending {
        h3 {
          color: ${styles.heading};
        }
      }

      .card {
        h3 {
          span {
            color: ${styles.blue};
          }
        }
      }
    }
  }

  &.hovered {
    .card {
      box-shadow: 0px 5px 15px 0px rgba(45, 47, 56, 0.1);
    }
  }

  &.in_progress {
    .card {
      border-color: ${styles.secondary};

      h3 {
        span {
          color: ${styles.secondary};
        }
      }
    }
  }

  &.complete {
    .card {
      border-color: ${styles.primary};

      h3 {
        color: ${styles.primary};

        span {
          color: ${styles.primary};
        }
      }
    }
  }

  .card {
    position: relative;
    height: 100%;
    padding: 25px 20px;
    box-shadow: 0px 5px 15px 0px rgba(45, 47, 56, 0.05);

    h3 {
      color: ${styles.heading};
      font-family: ${styles.nationalMedium};
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      transition: color 300ms;

      span {
        transition: color 300ms;
        font-family: ${styles.nationalRegular};
      }
    }
  }

  .status_heading {
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    font-family: ${styles.nationalBold};
    letter-spacing: 0.1em;
    margin-bottom: 25px;
    transition: color 300ms;

    &.pending {
      text-transform: none;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: ${styles.grayed};
      font-family: ${styles.nationalRegular};
    }

    &.in_progress {
      color: ${styles.heading};

      & > span {
        // text-transform: none;
        font-size: 13px;
        letter-spacing: 0.1em;
        font-family: ${styles.nationalRegular};
        color: ${styles.secondary};
      }
    }

    &.complete {
      color: ${styles.primary};
    }
  }

  .status_footer {
    display: block;
    margin-top: 20px;
    font-size: 13px;
    color: ${styles.textGrayed};
    font-family: ${styles.nationalRegular};

    .orgs {
      color: ${styles.secondary};
    }
  }

  .icon {
    height: 70px;
    margin: 0 auto 25px;
  }

  .pipe {
    height: 13px;
    transform: translateY(3px);
    margin: 0 10px;
  }

  .status_badge {
    position: absolute;
    top: -4px;
    right: -4px;
  }

  &:not(.for_tooltip) {
    .card {
      cursor: pointer;
    }
  }

  &.for_tooltip {
    position: absolute;
  }
`;

const BestPracticesCardWithRouter = withRouter(StyledBestPracticesCard);

export { BestPracticesCardWithRouter as BestPracticesCard };
