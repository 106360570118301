import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Logo, SideMenuNav } from '../components';


const SideMenu = ({ className }) => (
  <div className={className}>
    <Logo anchor={process.env.REACT_APP_API_BASE} />
    <SideMenuNav />
  </div>
);

const StyledSideMenu = styled(SideMenu)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${styles.menuWidth}px;
  padding: ${styles.pagePaddingTop}px 0;
  background: #fff;
  z-index: 500;
  box-shadow: rgba(0, 0, 0, 0.04) 2px 0 10px, rgba(0, 0, 0, 0.05) 2px 0 20px;
`;

export { StyledSideMenu as SideMenu };
