import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Modal, Button, RadioButton } from '../components';


const BestPracticePageFeedbackModal = ({ className, show, toggle, handleSubmitFeedback, resource, loading }) => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { title } = resource;

  const handleSubmit = () => {
    handleSubmitFeedback(rating, feedback);
    setSubmitted(true);
  };

  const toggleConfirmaion = () => {
    setRating(null);
    setFeedback('');
    setSubmitted(false);
    toggle();
  };

  return (
    <Fragment>
      <Modal
        className={className}
        show={show && submitted && !loading}
        toggle={toggleConfirmaion}
        size="sm"
      >
        <p>Thank you! Your feedback has been submitted.</p>
        <Button buttonStyle="primary" handleClick={toggleConfirmaion}>Ok</Button>
      </Modal>

      <Modal
        className={className}
        show={show && (!submitted || (submitted && loading))}
        toggle={toggle}
        size="md"
        backdrop="static"
        noCloser
      >
        <h3>{title}</h3>
        <p>
          Please let us know how useful the tool was. Explain your rating and any suggestions for how the tool could be more useful.
        </p>
        <div>
          <ul className="radio_questions">
            <li>
              <span>Not Useful</span>
              <RadioButton value={1} name="bp_rating" checked={rating === 1} handleSelectAnswer={() => setRating(1)} />
            </li>
            <li>
              <span className="value">2</span>
              <RadioButton value={2} name="bp_rating" checked={rating === 2} handleSelectAnswer={() => setRating(2)} />
            </li>
            <li>
              <span className="value">3</span>
              <RadioButton value={3} name="bp_rating" checked={rating === 3} handleSelectAnswer={() => setRating(3)} />
            </li>
            <li>
              <span className="value">4</span>
              <RadioButton value={4} name="bp_rating" checked={rating === 4} handleSelectAnswer={() => setRating(4)} />
            </li>
            <li>
              <span>Very Useful</span>
              <RadioButton value={5} name="bp_rating" checked={rating === 5} handleSelectAnswer={() => setRating(5)} />
            </li>
          </ul>
          <textarea
            className="comments_textarea"
            name="comments"
            value={feedback}
            placeholder="Feedback"
            onChange={e => setFeedback(e.currentTarget.value)}
            rows="4"
          />
        </div>
        <div className="button_container">
          <Button
            className="button__cancel"
            buttonStyle="secondary outline"
            handleClick={toggle}
            disabled={loading}
          >Cancel</Button>
          <Button
            className="button__submit"
            handleClick={handleSubmit}
            loading={loading}
            disabled={!rating || loading}
          >Submit</Button>
        </div>
      </Modal>
    </Fragment>
  );
}


const StyledBestPracticePageFeedbackModal = styled(BestPracticePageFeedbackModal)`
  text-align: center;

  .modal-body {
    // padding: 40px 30px 30px !important;
  }

  h3 {
    font-weight: normal;
    color: ${styles.heading};
    font-size: 20px;
    font-family: ${styles.nationalMedium};
    letter-spacing: 0.01em;
    margin: 0 0 25px;
  }

  p {
    margin: 0 0 20px;
    color: ${styles.headingLight};
    font-size: 17px;
  }

  .radio_questions {
    span {
      color: ${styles.heading};
    }
  }

  .comments_textarea {
    margin-top: 20px;
  }

  .button {
    min-width: 120px;
    margin-top: 10px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    &__cancel {
      float: left;
    }

    &__submit {
      float: right;
    }
  }

  .loader {
    margin-left: 0;
    position: absolute;
    right: 154px;
    bottom: 42px;
  }
`;

export { StyledBestPracticePageFeedbackModal as BestPracticePageFeedbackModal };
