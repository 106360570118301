import React from 'react';
import styled from 'styled-components';
import MDSpinner from 'react-md-spinner';
import styles from '../../styles';

const Loader = ({ className, size, border, color1, color2, color3, color4 }) => (
  <div className={`loader ${className}`}>
    <MDSpinner
      size={size || 19}
      borderSize={border || 2}
      color1={color1 || styles.primary}
      color2={color2 || styles.secondary}
      color3={color3 || styles.logoBlue}
      color4={color4 || styles.burgandy}
    />
  </div>
);

const StyledLoader = styled(Loader)`
  display: inline-block;
`;

export { StyledLoader as Loader };
