import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const SectionTitle = ({ className, title, children }) => (
  <div className={`section_title ${className} float_clear`}>
    {title && <h2 className="prop_title">{title}</h2>}
    {children}
  </div>
);


const StyledSectionTitle = styled(SectionTitle)`
  h2 {
    color: ${styles.heading};
    font-size: 16px;
    margin: 0;
    float: left;

    &.prop_title {
      margin-bottom: 25px;
    }
  }

  ul {
    margin: 0;
    float: right;
  }

  p,
  li {
    display: inline-block;
    font-size: 14px;
    margin: 0;
  }

  p {
    float: left;
    margin: 0 0 0 30px;
  }

  li {
    transform: translateY(-3px);
    
    &:not(:first-of-type) {
      margin-left: 18px;
    }
  }
`;

export { StyledSectionTitle as SectionTitle };
