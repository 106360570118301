import React from 'react';
import styled from 'styled-components';

const CardContent = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
);

const StyledCardContent = styled(CardContent)`
  padding: 20px;
`;

export { StyledCardContent as CardContent };
