import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Header,
  PageTabs,
  SectionTitle,
  SetupTeamMembers,
  Button,
  withData
} from '../components';
import { POST, GET } from '../../libs';

const pageTabs = [
  { url: '/team', label: 'Manage Team' },
  { url: '/team/add', label: 'Add Team Members' }
];

const emptyTeamMember = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  validFirstName: true,
  validLastName: true,
  validEmail: true,
  valid: true,
  errorMessage: 'Enter a valid email.'
};


class TeamAddMembers extends React.Component {
  state = {
    teamMembers: [emptyTeamMember],
    loading: false
  };

  handleInputChange = (name, value, index) => {
    const { teamMembers } = this.state;
    const newTeamMembers = teamMembers.map((member, i) => {
      const newMember = Object.assign({}, member);
      if (i === index) newMember[name] = value;
      return newMember;
    });
    this.setState({ teamMembers: newTeamMembers });
  };

  handleAddTeamMember = () => {
    const { teamMembers } = this.state;
    teamMembers.push(emptyTeamMember);
    this.setState({ teamMembers });
  };

  handleRemoveTeamMember = index => {
    const { teamMembers } = this.state;
    teamMembers.splice(index, 1);
    this.setState({ teamMembers });
  };

  handleTeamMemberSubmission = () => {
    this.setState({ loading: true });
    const { teamMembers } = this.state;
    const emailValidations = [];
    let validTeam = true;

    teamMembers.forEach(member => {
      emailValidations.push(POST.validateUserEmail(member.email))
    });

    const validatedTeamMembers = teamMembers.map(member => {
      const newMember = Object.assign({}, member);
      newMember.valid = true;
      newMember.errorMessage = emptyTeamMember.errorMessage;

      if (!newMember.firstName) {
        newMember.validFirstName = false;
        validTeam = false;
      } else {
        newMember.validFirstName = true;
      }

      if (!newMember.lastName) {
        newMember.validLastName = false;
        validTeam = false;
      } else {
        newMember.validLastName = true;
      }

      if (!newMember.email) {
        newMember.validEmail = false;
        validTeam = false;
      } else {
        newMember.validEmail = true;
      }

      return newMember;
    });

    this.setState({ teamMembers: validatedTeamMembers }, () => {
      if (validTeam) {
        this.validateEmails(emailValidations);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  validateEmails = emailValidations => {
    const { teamMembers } = this.state;
    const { getGroupId, addUsers, history } = this.props;
    const groupId = getGroupId();

    Promise.all([ ...emailValidations ])
      .then(res => {
        const validations = res.map(val => val.data);
        const invalids = [];
        // let newTeamMembers;

        validations.forEach((val, i) => {
          if (!val) invalids.push(i);
        });

        const newTeamMembers = teamMembers.map((member, i) => {
          const newMember = Object.assign({}, member);

          if (!invalids.length) {
            const newMember = Object.assign({}, member);
            newMember.valid = true;
            newMember.errorMessage = emptyTeamMember.errorMessage;
            return newMember;
          }

          if (newMember.email === '') { // TODO: add email validator //
            newMember.valid = false;
          } else if (invalids.includes(i)) {
            newMember.valid = false;
            newMember.errorMessage = 'This email is already in use.';
          }
          return newMember;
        });

        this.setState({ teamMembers: newTeamMembers }, () => {
          const { teamMembers } = this.state;

          if (teamMembers.every(member => member.valid)) {
            const formattedTeamMembers = teamMembers.map(member => ({
              first_name: member.firstName,
              last_name: member.lastName,
              title: member.title,
              email: member.email,
              password: 'na',
              phone: 'na'
            }));

            POST.saveTeamMembers(formattedTeamMembers, groupId)
              .then(() => GET.teamMembers(groupId))
              .then(res => {
                addUsers(res.data);
                history.push('/team');
              })
              .catch(err => {
                console.error(err);
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
          }
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  render() {
    const { className } = this.props;
    const { teamMembers, loading } = this.state;

    return (
      <div className={className}>
        <Header title="My Team" />
        <PageTabs tabs={pageTabs} />
        <div className="team_members_container">
          <SectionTitle title="New Team Members" />
          <SetupTeamMembers
            teamMembers={teamMembers}
            onInputChange={this.handleInputChange}
            onAddTeamMember={this.handleAddTeamMember}
            onRemoveTeamMember={this.handleRemoveTeamMember}
          />
          <div className="button_container">
            <Button handleClick={this.handleTeamMemberSubmission} disabled={loading} loading={loading}>
              Add Team Member{teamMembers.length > 1 ? 's' : ''}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}


const StyledTeamAddMembers = styled(TeamAddMembers)`
  .team_members_container {
    margin-top: 35px;
  }

  .button_container {
    margin-top: 30px;
  }
`;

const TeamAddMembersWithData = withData(StyledTeamAddMembers);
const TeamAddMembersWithRouter = withRouter(TeamAddMembersWithData);
export { TeamAddMembersWithRouter as TeamAddMembers };
