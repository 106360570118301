import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';

import { SetupTeamMembers, Button, withData } from '../components';

import { POST } from '../../libs';

const emptyTeamMember = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  whyJoined: '',
  validFirstName: true,
  validLastName: true,
  validEmail: true,
  valid: true,
  errorMessage: 'Enter a valid email.'
};


class SetupCard extends React.Component {
  state = {
    step: 1,
    teamMembers: [emptyTeamMember],
    whyJoinedValid: true,
    loading: false
  };

  goToStep = step => {
    this.setState({ step });
  };

  handleInputChange = (name, value, index) => {
    const { teamMembers } = this.state;
    const newTeamMembers = teamMembers.map((member, i) => {
      const newMember = Object.assign({}, member);
      if (i === index) newMember[name] = value;
      return newMember;
    });
    this.setState({ teamMembers: newTeamMembers });
  };

  handleWhyJoinedChange = target => {
    this.setState({ whyJoined: target.value });
  };

  handleAddTeamMember = () => {
    const { teamMembers } = this.state;
    teamMembers.push(emptyTeamMember);
    this.setState({ teamMembers });
  };

  handleRemoveTeamMember = index => {
    const { teamMembers } = this.state;
    teamMembers.splice(index, 1);
    this.setState({ teamMembers });
  };

  handleTeamMemberSubmission = () => {
    this.setState({ loading: true });
    const { teamMembers } = this.state;
    const emailValidations = [];
    let validTeam = true;

    teamMembers.forEach(member => {
      emailValidations.push(POST.validateUserEmail(member.email))
    });

    const validatedTeamMembers = teamMembers.map(member => {
      const newMember = Object.assign({}, member);
      newMember.valid = true;
      newMember.errorMessage = emptyTeamMember.errorMessage;

      if (!newMember.firstName) {
        newMember.validFirstName = false;
        validTeam = false;
      } else {
        newMember.validFirstName = true;
      }

      if (!newMember.lastName) {
        newMember.validLastName = false;
        validTeam = false;
      } else {
        newMember.validLastName = true;
      }

      if (!newMember.email) {
        newMember.validEmail = false;
        validTeam = false;
      } else {
        newMember.validEmail = true;
      }

      return newMember;
    });

    this.setState({ teamMembers: validatedTeamMembers }, () => {
      if (validTeam) {
        this.validateEmails(emailValidations);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  validateEmails = emailValidations => {
    const { teamMembers } = this.state;
    const { groupId } = this.props;

    Promise.all([ ...emailValidations ])
      .then(res => {
        const validations = res.map(val => val.data);
        const invalids = [];
        let newTeamMembers;

        validations.forEach((val, i) => {
          if (!val) invalids.push(i);
        });

        if (!invalids.length) {
          newTeamMembers = teamMembers.map((member, i) => {
            const newMember = Object.assign({}, member);
            newMember.valid = true;
            newMember.errorMessage = emptyTeamMember.errorMessage;
            return newMember;
          });
        } else {
          newTeamMembers = teamMembers.map((member, i) => {
            const newMember = Object.assign({}, member);

            if (newMember.email === '') { // TODO: add email validator //
              newMember.valid = false;
            } else if (invalids.includes(i)) {
              newMember.valid = false;
              newMember.errorMessage = 'This email is already in use.';
            }
            return newMember;
          });
        }

        this.setState({ teamMembers: newTeamMembers }, () => {
          const { teamMembers } = this.state;

          if (teamMembers.every(member => member.valid)) {
            const formattedTeamMembers = teamMembers.map(member => ({
              first_name: member.firstName,
              last_name: member.lastName,
              title: member.title,
              email: member.email,
              password: 'na',
              phone: 'na'
            }));

            POST.saveTeamMembers(formattedTeamMembers, groupId)
              .then(res => {
                this.setState({ loading: false });
                this.goToStep(2);
              })
              .catch(err => {
                console.error(err);
                this.setState({ loading: false });
                this.goToStep(2);
              });
          } else {
            this.setState({ loading: false });
          }
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.error(err);
      });
  };

  completeSetup = () => {
    const { whyJoined } = this.state;
    const { groupId, history, loadAppData, loadPractices, loadTeamMembers } = this.props;

    this.setState({ whyJoinedValid: true, loading: true })

    if (whyJoined) {
      this.setState({ whyJoinedValid: true });

      POST.completeSetup(groupId, whyJoined)
        .then(res => {
          Promise.all([
            loadAppData(),
            loadPractices(),
            loadTeamMembers()
          ])
          .then(() => {
            history.push('/');
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({ loading: false }, () => {
            history.push('/');
          });
        });
    } else {
      this.setState({ whyJoinedValid: false, loading: false });
    }
  };

  renderStep = () => {
    const { step, teamMembers, whyJoined, whyJoinedValid, loading } = this.state;

    switch (step) {
      case 1:
        return (
          <Fragment>
            <SetupTeamMembers
              teamMembers={teamMembers}
              onInputChange={this.handleInputChange}
              onAddTeamMember={this.handleAddTeamMember}
              onRemoveTeamMember={this.handleRemoveTeamMember}
            />

            <div className="button_container float_clear">
              <Button
                buttonStyle="primary shadow"
                handleClick={this.handleTeamMemberSubmission}
                disabled={loading}
              >Continue</Button>
              <span
                className="skip_step"
                onClick={() => this.goToStep(2)}
              >Skip Step</span>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <textarea
              className={!whyJoinedValid ? 'error' : ''}
              rows="6"
              value={whyJoined}
              onChange={e => this.handleWhyJoinedChange(e.target)}
            />
            {!whyJoinedValid && (
              <div className="input_error">Please enter a reason for joining.</div>
            )}

            <div className="button_container float_clear">
              <span
                className="previous_step"
                onClick={() => this.goToStep(1)}
              >Add Team Members</span>
              <Button
                buttonStyle="primary shadow"
                handleClick={this.completeSetup}
                disabled={loading}
              >Complete Setup</Button>
            </div>
          </Fragment>
        );
    }
  };

  render() {
    const { className } = this.props;
    const { step } = this.state;

    return (
      <div className={className}>
        <ul className="steps">
          <li className={step === 1 ? 'active' : ''}>Step 1</li>
          <li className={step === 2 ? 'active' : ''}>Step 2</li>
        </ul>
        {step === 1 ? (
          <h3>Please add your campaign team members:</h3>
        ) : (
          <h3>Please share why your organization has decided to<br />take part in the Aleinu campaign:</h3>
        )}
        {this.renderStep()}
      </div>
    );
  }
}


const StyledSetupCard = styled(SetupCard)`
  margin-top: 55px;
  padding: 40px 50px 50px;
  border: 1px solid ${styles.border};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 16px 55px 0px rgba(45, 47, 56, 0.1);

  .steps {
    li {
      display: inline-block;
      letter-spacing: 0.18em;
      color: #999da6;
      font-size: 13px;
      text-transform: uppercase;
      margin: 0 20px;

      &.active {
        color: ${styles.primary};
      }
    }
  }

  h3 {
    font-size: 18px;
    color: ${styles.heading};
    margin: 25px 0 35px;
    line-height: 1.5;
  }

  textarea {
    margin-bottom: 30px;

    &.error {
      margin-bottom: 2px;
    }

    & + .input_error {
      margin-bottom: 12px;
    }
  }

  .button_container {
    margin-top: 10px;

    .skip_step {
      float: right;
      font-size: 13px;
      color: #999da6;
      margin: 10px 20px 0 0;
      cursor: pointer;
    }

    .previous_step {
      margin-top: 8px;
      color: ${styles.primary};
      font-size: 13px;
      cursor: pointer;
      transition: color 50ms;

      &:hover {
        color: ${styles.primaryHover};
        transition: color 300ms;
      }
    }

    .button {
      float: right;
    }

    .previous_step {
      float: left;
    }
  }
`;

const SetupCardWithRouter = withRouter(StyledSetupCard);
const SetupCardWithData = withData(SetupCardWithRouter);
export { SetupCardWithData as SetupCard };
