import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

import { withData } from '../components';

import queryString from 'query-string';


class AuthRoute extends Route {
  render() {
    const { component: Component, getUser, history: { location }, ...rest } = this.props;
    const user = getUser();
    const params = queryString.parse(location.search);
    const { setup: groupUuid, token, email, wizard, action, key, login } = params;

    if (user) {
      return <Component {...rest} />
    } else if (groupUuid && location.pathname === '/' && (wizard || (token && email))) {
      const redirectTo = {
        pathname: `/setup/${groupUuid}`,
        state: { token: decodeURIComponent(token), email: decodeURIComponent(email), wizard }
      };

      return <Redirect to={redirectTo} />;
    } else {
      const redirectTo = (action && key && login)
        ? {
          pathname: '/login',
          state: { action, key, login }
        }
        : '/login';

      return <Redirect to={redirectTo} />;
    }
  }
}


const AuthRouteWithData = withData(AuthRoute);
const AuthRouteWithRouter = withRouter(AuthRouteWithData);
export { AuthRouteWithRouter as AuthRoute };
