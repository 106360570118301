import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  RadioButton,
  SelectBox,
  CheckBox
} from '../components';

import { shouldShowConditionalSurveyQuestion } from '../../libs';


const BestPracticePageSurvey = ({ className, questions, handleAnswerQuestion }) => {
  const handleAnswerCheckbox = (name, value, answerString) => {
    const answers = answerString.split('~');
    let newAnswers;

    if (answers.includes(value)) {
      newAnswers = answers.filter(a => a !== value);
    } else {
      answers.push(value);
      newAnswers = answers;
    }

    // remove other textarea answer if Other is deselected //
    if (!newAnswers.includes('otherOption')) {
      newAnswers = newAnswers.filter(a => !a.includes('other='));
    }

    handleAnswerQuestion(name, newAnswers.filter(a => a !== '').join('~'));
  };

  const handleAnswerCheckboxOther = (name, value, answerString) => {
    const answers = answerString.split('~');
    const newAnswers = answers.filter(a => !a.includes('other='));

    newAnswers.push(`other=${value}`);

    handleAnswerQuestion(name, newAnswers.filter(a => a !== '').join('~'));
  };

  const getOtherAnswerText = answer => {
    const answers = answer.split('~');
    const otherAnswer = answers.filter(a => a.includes('other='));
    return Array.isArray(otherAnswer) && otherAnswer.length ? otherAnswer[0].replace(/other=/, '') : '';
  };

  const renderFormQuestion = q => {
    switch (q.answer_type) {
      case 'scale':
        return (
          <ul className="radio_questions">
            <li>
              <span>Strongly Disagree</span>
              <RadioButton value={1} name={q.id} checked={q.answer === 1} handleSelectAnswer={handleAnswerQuestion} />
            </li>
            <li>
              <span className="value">2</span>
              <RadioButton value={2} name={q.id} checked={q.answer === 2} handleSelectAnswer={handleAnswerQuestion} />
            </li>
            <li>
              <span className="value">3</span>
              <RadioButton value={3} name={q.id} checked={q.answer === 3} handleSelectAnswer={handleAnswerQuestion} />
            </li>
            <li>
              <span className="value">4</span>
              <RadioButton value={4} name={q.id} checked={q.answer === 4} handleSelectAnswer={handleAnswerQuestion} />
            </li>
            <li>
              <span>Strongly Agree</span>
              <RadioButton value={5} name={q.id} checked={q.answer === 5} handleSelectAnswer={handleAnswerQuestion} />
            </li>
          </ul>
        );
      case 'dropdown':
        return (
          <SelectBox
            options={q.selections.map(o => o.text)}
            value={q.answer}
            name={q.id}
            handleSelectAnswer={(name, value) => handleAnswerQuestion(name, value)}
          />
        );
      case 'multi':
        return (
          <Fragment>
            <ul>
              {q.choices && q.choices.map((choice, i) => {
                console.log(choice, q);
                return (
                  <li key={i}>
                    <CheckBox
                      name={q.id}
                      value={choice.text}
                      checked={q.answer && q.answer.split('~').includes(choice.text)}
                      handleSelectAnswer={(name, value) => handleAnswerCheckbox(name, value, q.answer)}
                    />
                  </li>
                )
              })}
              {q.includeOther && (
                <li key="otherOption">
                  <CheckBox
                    name={q.id}
                    value="otherOption"
                    label="Other"
                    checked={q.answer && q.answer.split('~').includes('otherOption')}
                    handleSelectAnswer={(name, value) => handleAnswerCheckbox(name, value, q.answer)}
                  />
                </li>
              )}
            </ul>
            {q.includeOther && q.answer.includes('otherOption') && (
              <textarea
                className="other_textarea"
                name={q.id}
                value={getOtherAnswerText(q.answer)}
                placeholder="Please describe"
                onChange={e => handleAnswerCheckboxOther(q.id, e.target.value, q.answer)}
                rows="4"
              />
            )}
          </Fragment>
        );
      case 'textarea':
        return (
          <textarea
            name={q.id}
            value={q.answer}
            onChange={e => handleAnswerQuestion(q.id, e.target.value)}
            rows="4"
          />
        );
      case 'number':
        return (
          <input
            type="number"
            name={q.id}
            value={q.answer}
            onChange={e => handleAnswerQuestion(q.id, e.target.value)}
          />
        );
      default:
        return (
          <input
            type="text"
            name={q.id}
            value={q.answer}
            onChange={e => handleAnswerQuestion(q.id, e.target.value)}
          />
        );
    }
  };

  return (
    <ul className={className}>
      {questions.length > 0 && questions.map(q => {
        if (!shouldShowConditionalSurveyQuestion(q, questions)) return null;
        const questionLabel = `${q.question}${q.required ? '*' : ''}`;

        return (
          <li key={q.id}>
            <label dangerouslySetInnerHTML={{ __html: questionLabel }} />
            {renderFormQuestion(q)}
          </li>
        );
      })}
    </ul>
  );
}

const StyledBestPracticePageSurvey = styled(BestPracticePageSurvey)`
  padding: 0 !important;
  list-style-type: none !important;

  ul {
    padding: 0 !important;
    list-style-type: none !important;
  }

  .other_textarea {
    margin-top: -10px;
  }

  label {
    font-family: ${styles.nationalRegular};
    color: ${styles.heading};
  }
`;

export { StyledBestPracticePageSurvey as BestPracticePageSurvey };
