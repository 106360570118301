import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { SetupTeamMemberItem } from '../components';


const SetupTeamMembers = ({ className, teamMembers, onInputChange, onAddTeamMember, onRemoveTeamMember }) => (
  <div className={className}>
    {teamMembers.map((member, i) => (
      <SetupTeamMemberItem
        key={i}
        index={i}
        member={member}
        numberOfMembers={teamMembers.length}
        onInputChange={onInputChange}
        onRemoveTeamMember={onRemoveTeamMember}
      />
    ))}
    <div className="add_member">
      <span onClick={onAddTeamMember}>+ Add Another Team Member</span>
    </div>
  </div>
);


const StyledSetupTeamMembers = styled(SetupTeamMembers)`
  .add_member {
    text-align: left;

    span {
      font-size: 14px;
      color: ${styles.primary};
      cursor: pointer;
      transition: color 50ms;

      &:hover {
        color: ${styles.primaryHover};
        transition: color 300ms;
      }
    }
  }
`;

export { StyledSetupTeamMembers as SetupTeamMembers };
