import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Input, Button, ToggleButtons, withData } from '../components';

import { POST, validate } from '../../libs';

const defaultValidFields = {
  firstName: true,
  lastName: true,
  email: true,
  title: true,
  role: true
};

const defaultEmailErrorMessage = 'Enter a valid email.';

const defaultValidation = {
  validFields: defaultValidFields,
  emailErrorMessage: defaultEmailErrorMessage
};


class TeamEditMemberModalForm extends React.Component {
  state = {
    id: null,
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    group_role: '',
    loading: false,
    ...defaultValidation
  };

  componentDidMount() {
    const { member } = this.props;

    this.setState({
      id: member.id,
      first_name: member.first_name,
      last_name: member.last_name,
      title: member.title,
      email: member.email,
      group_role: member.group_role
    });
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleUpdateUser = () => {
    const { teamMembers } = this.props;
    const userData = Object.assign({}, this.state);
    const validFields = Object.assign({}, userData.validFields);

    const savedMember = teamMembers.filter(member => member.id === userData.id);

    this.setState({ ...defaultValidation, loading: true });

    if (savedMember.length && savedMember[0].email === userData.email) {
      delete userData.email;
      this.validateAndUpdateUser(userData);
    } else {
      POST.validateUserEmail(userData.email)
        .then(({ data: uniqueEmail }) => {
          if (uniqueEmail) {
            this.validateAndUpdateUser(userData);
          } else {
            validFields.email = false;
            this.setState({ validFields, emailErrorMessage: 'This email is already in use.', loading: false });
          }
        });
    }
  };

  validateAndUpdateUser = userData => {
    const { updateTeamMember, showConfirmation } = this.props;

    const data = {
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      title: userData.title,
      group_role: userData.group_role
    };

    if (userData.email) data.email = userData.email;

    if (this.formIsValid()) {
      updateTeamMember(data).then(res => this.setState({ loading: false }, () => showConfirmation()));
    } else {
      console.log('invalid');
      this.setState({ loading: false });
    }
  };

  formIsValid = () => {
    const { email, first_name, last_name } = this.state;
    const validFields = Object.assign({}, defaultValidFields);
    let isValid = true;

    if (!validate.email(email)) {
      validFields.email = false;
      isValid = false;
      this.setState({ emailErrorMessage: defaultEmailErrorMessage });
    }

    if (!first_name) {
      validFields.firstName = false;
      isValid = false;
    }

    if (!last_name) {
      validFields.lastName = false;
      isValid = false;
    }

    this.setState({ validFields });
    return isValid;
  };

  render() {
    const { className, toggleModal } = this.props;
    const { loading, first_name, last_name, title, email, group_role, validFields, emailErrorMessage } = this.state;

    return (
      <div className={className}>
        <h2>Edit Team Member</h2>
        <form noValidate>
          <div className="input_row with_label">
            <label>First Name</label>
            <Input
              name="first_name"
              value={first_name}
              onChange={this.handleInputChange}
              errorMessage="Enter a valid first name."
              shouldSetError={!validFields.firstName}
              required
            />
          </div>
          <div className="input_row with_label">
            <label>Last Name</label>
            <Input
              name="last_name"
              value={last_name}
              onChange={this.handleInputChange}
              errorMessage="Enter a valid last name."
              shouldSetError={!validFields.lastName}
              required
            />
          </div>
          <div className="input_row with_label">
            <label>Title</label>
            <Input
              name="title"
              value={title}
              onChange={this.handleInputChange}
              shouldSetError={!validFields.title}
            />
          </div>
          <div className="input_row with_label">
            <label>Email</label>
            <Input
              name="email"
              value={email}
              onChange={this.handleInputChange}
              errorMessage={emailErrorMessage}
              shouldSetError={!validFields.email}
              required
            />
          </div>
          <div className="input_row with_label">
            <label>Role</label>
            <ToggleButtons
              name="group_role"
              values={{
                admin: 'admin',
                member: 'team member'
              }}
              active={group_role}
              onChange={this.handleInputChange}
            />
          </div>
        </form>

        <div className="modal_footer">
          <div className="float_left">
            <Button
              handleClick={toggleModal}
              buttonStyle="primary outline"
            >Cancel</Button>
          </div>
          <div className="float_right">
            <Button
              handleClick={this.handleUpdateUser}
              loading={loading}
              disabled={loading}
              loaderPos="left"
            >Update</Button>
          </div>
        </div>
      </div>
    );
  }
}


const StyledTeamEditMemberModalForm = styled(TeamEditMemberModalForm)`
  padding: 40px 0 0;

  h2 {
    font-family: ${styles.nationalLight};
    text-align: center;
    font-size: 40px;
    color: ${styles.heading};
  }

  form {
    margin: 40px 0 20px;
    padding: 0 50px;
  }
`;

const TeamEditMemberModalFormWithData = withData(StyledTeamEditMemberModalForm);
export { TeamEditMemberModalFormWithData as TeamEditMemberModalForm };
