import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Dashboard,
  BestPracticePageModal,
  withData
} from '../components';

let ticking = false;


class BestPracticePage extends React.Component {
  state = {
    showOverlay: false,
    showModal: false,
    practice: null,
    absolutelyPositionHelp: false
  };

  modal = React.createRef();

  componentDidMount() {
    const { practices } = this.props;
    const modal = this.modal.current;
    if (practices) this.loadPractice(practices);
    modal.addEventListener('scroll', this.handlePageScroll, true);
  }

  componentWillReceiveProps(newProps) {
    const { practice } = this.state;
    if (practice === null && newProps.practices) {
      this.loadPractice(newProps.practices);
    }
  }

  componentWillUnmount() {
    const modal = this.modal.current;
    modal.removeEventListener('scroll', this.handlePageScroll);
  }

  loadPractice = practices => {
    const { history, computedMatch: { params: { id } } } = this.props;
    const practice = practices.filter(practice => practice.id === parseInt(id));

    // console.log(practices);

    if (!practice.length) return history.push('/');

    this.setState({ practice: practice[0] }, () => {
      setTimeout(() => {
        this.setState({ showOverlay: true });
      }, 50);
      setTimeout(() => {
        this.setState({ showModal: true });
        document.body.classList.add('bp_open');
      }, 250);
    });
  };


  handleCloseModal = () => {
    const { history } = this.props;

    this.setState({ showModal: false }, () => {
      document.body.classList.remove('bp_open');
      this.setState({ showOverlay: false });
    });

    setTimeout(() => {
      history.push('/');
    }, 300);
  };

  handlePageScroll = e => {
    const { absolutelyPositionHelp } = this.state;
    const modal = this.modal.current;
    const modalElement = modal.querySelector('.modal_element');
    const scrollY = modal.scrollTop;

    if (!ticking && modalElement) {
      window.requestAnimationFrame(() => {
        const isAtBottom = (window.innerHeight + scrollY + 40) >= modalElement.offsetHeight + 80;

        if (isAtBottom && !absolutelyPositionHelp) {
          this.setState({ absolutelyPositionHelp: true });
        } else if (!isAtBottom && absolutelyPositionHelp) {
          this.setState({ absolutelyPositionHelp: false });
        }
        ticking = false;
      });

      ticking = true;
    }
  };


  render() {
    const { className, practices } = this.props;
    const { showOverlay, showModal, practice, absolutelyPositionHelp } = this.state;
    const overlayClasses = [ 'overlay' ];

    if (showOverlay) overlayClasses.push('show');

    return (
      <div className={className}>
        <Dashboard />
        <div
          className={overlayClasses.join(' ')}
          onClick={this.handleCloseModal}
          ref={this.modal}
        >
          {showModal && practice && (
            <BestPracticePageModal
              className="modal_element"
              practices={practices}
              practice={practice}
              handleCloseModal={this.handleCloseModal}
              absolutelyPositionHelp={absolutelyPositionHelp}
            />
          )}
        </div>
      </div>
    );
  }
}

const StyledBestPracticePage = styled(BestPracticePage)`

  .overlay {
    padding: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 600;
    opacity: 0;
    transition: opacity 300ms;
    overflow: auto;

    &.show {
      opacity: 1;
    }
  }
`;

const BestPracticePageWithRouter = withRouter(StyledBestPracticePage);
const BestPracticePageWithData = withData(BestPracticePageWithRouter);

export { BestPracticePageWithData as BestPracticePage };
