import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Icon, Button } from '../components';

import { BestPracticePageResource } from '../components';

import { percentComplete, completionTime } from '../../libs';


const BestPracticePagePhase = props => {
  const {
    className,
    practice,
    phase,
    phaseIndex,
    handleShowVideo,
    handleShowDocument,
    canStartPractice,
    canStartPhase,
    canDownloadTools
  } = props;

  const toComplete = percentComplete(phase, 'phase');

  return (
    <div className={className}>
      <h3>
        {phase.title}{' '}
        {phase.all_phase_tools_file && (
          <Button
            link={phase.all_phase_tools_file}
            className="download_all_link"
            buttonStyle="simple"
            tooltip={canDownloadTools ? 'Download' : 'Please complete the Pre-Practice Survey before downloading tools.'}
            disabled={!canDownloadTools}
          >
            <i className="fa fa-download" aria-hidden="true"></i>
          </Button>
        )}
      </h3>
      <div className="phase_details">
        {toComplete !== 100 ? (
          <Fragment>
            <Icon name="clock" color={styles.textGrayed} />
            {completionTime(phase, 'phase')}<i className="pipe" />{toComplete}% complete
          </Fragment>
        ) : (
          'Complete'
        )}
      </div>
      <ul>
        {phase.resources && phase.resources.map(resource => (
          <BestPracticePageResource
            key={resource.id}
            practice={practice}
            phase={phase}
            phaseIndex={phaseIndex}
            resource={resource}
            handleShowVideo={handleShowVideo}
            handleShowDocument={handleShowDocument}
            canStartPractice={canStartPractice}
            canStartPhase={canStartPhase}
          />
        ))}
      </ul>
    </div>
  );
};


const StyledBestPracticePagePhase = styled(BestPracticePagePhase)`
  margin: 40px 0 10px;

  .phase_details {
    margin: -15px 0 30px;
    text-align: center;
    font-size: 14px;
    font-family: ${styles.nationalRegular};
    color: ${styles.textGrayed};
    letter-spacing: 0.01em;

    .icon {
      position: relative;
      width: 13px;
      display: inline-block;
      transform: translateY(2px);
      margin-right: 10px;
    }

    .pipe {
      margin: 0 13px;
    }
  }

  .download_all_link {
    display: inline-block;
    // margin-top: 12px;
    font-size: 16px;
    margin-left: 8px;
    color: ${styles.primary};

    &::hover {
      color: ${styles.primaryHover};
    }

    .tooltip {
      margin-left: -4px;
    }
  }
`;

export { StyledBestPracticePagePhase as BestPracticePagePhase };
