import React from 'react';
import { GET, POST, PUT, DELETE, addStatuses, getCookie, setCookie, deleteCookies } from '../../libs';
import moment from 'moment';
const Context = React.createContext();


class ContextProvider extends React.Component {
  state = {
    token: null,
    email: null,
    groupId: null,
    user: null,
    practices: null,
    teamMembers: null,
    data: null
  };

  componentDidMount() {
    const token = getCookie('auth_token');
    const email = getCookie('auth_email');

    if (token && email) this.setState({ token, email });
  }

  saveCookies = (token, email) => {
    this.setState({ token, email }, () => {
      setCookie(token, 'auth_token');
      setCookie(email, 'auth_email');
    });
  };

  removeCookies = () => {
    this.setState({ token: null, email: null }, () => deleteCookies());
  };

  loadTeamMembers = () => {
    const { groupId } = this.state;

    return GET.teamMembers(groupId)
      .then(resp => this.setState({ teamMembers: resp.data }))
      .catch(err => console.error(err));
  };

  loadPractices = () => {
    const { groupId } = this.state;

    return GET.practices(groupId)
      .then(resp => this.setState({ practices: addStatuses(resp.data) }))
      .catch(err => console.error(err));
  };

  loadAppData = () => {
    return GET.appData()
      .then(resp => this.setState({ data: resp.data }))
      .catch(err => console.error(err));
  };

  getAppData = () => {
    return this.state.data;
  }

  getUser = () => {
    return this.state.user;
  };

  setUser = data => {
    const user = {
      id: parseInt(data.id),
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      title: data.title,
      role: data.group_role,
      group_name: data.group_name,
      group_accepted: parseInt(data.group_accepted),
      tooltip_start: data.tooltip_start,
      tooltip_progress: data.tooltip_progress
    };
    const groupId = parseInt(data.group_id);

    this.setState({ user, groupId });
  };

  updateUser = (data, isTooltipUpdate) => {
    const { teamMembers: members, user } = this.state;
    const userData = Object.assign({}, user);

    for (const i in data) {
      userData[i] = data[i];
    }

    if (isTooltipUpdate) {
      PUT.updateTooltipStatus(data, userData.id);
      this.setState({ user: userData });
    } else {
      const teamMembers = members && members.map(member => {
        if (member.id === data.id) {
          member.first_name = data.first_name;
          member.last_name = data.last_name;
          member.title = data.title;

          if (data.email) member.email = data.email;
        }

        return member;
      });

      return PUT.updateUser(data, userData.id)
        .then(res => {
          this.setState({ user: userData, teamMembers });
          return res;
        });
    }
  };

  updateTeamMember = data => {
    const { teamMembers: members } = this.state;
    const teamMembers = members.map(member => {
      if (member.id === data.id) {
        member.first_name = data.first_name;
        member.last_name = data.last_name;
        member.title = data.title;
        member.group_role = data.group_role;

        if (data.email) member.email = data.email;
      }

      return member;
    });

    return PUT.updateUser(data, data.id)
      .then(res => {
        this.setState({ teamMembers });
        return res;
      });
  };

  deleteUser = user => {
    const { teamMembers } = this.state;
    const newTeamMembers = teamMembers.filter(member => member.id !== user.id);
    this.setState({ teamMembers: newTeamMembers });
    DELETE.user(user.id);
  };

  addUsers = teamMembers => {
    this.setState({ teamMembers });
  };

  getGroupId = () => {
    return this.state.groupId;
  };

  getGroup = () => {
    const { data, groupId } = this.state;
    return data && data.groups ? data.groups.find(g => g.id === groupId) : false;
  };

  updateGroup = groupData => {
    const { data, groupId } = this.state;
    const newData = Object.assign({}, data);
    const groupIndex = data.groups && data.groups.map(g => g.id).indexOf(groupId);

    if (groupIndex >= 0) newData.groups[groupIndex] = groupData;

    return PUT.updateGroup(groupData)
      .then(res => {
        this.setState({ data: newData });
        return res;
      });
  };

  getTeamMembers = () => {
    return this.state.teamMembers;
  };

  updatePracticeStatus = (status, practiceId) => {
    const { practices, groupId } = this.state;

    POST.setPracticeStatus(status, groupId, practiceId);

    practices.map(p => {
      if (p.id === practiceId) {
        p.status = status;
        if (status === 'in_progress') {
          p.started_on = moment();
        } else if (status === 'complete') {
          p.completed_on = moment();
        }
      }
      return p;
    });

    this.setState({ practices });
  };

  updatePhaseStatus = (status, practiceId, phaseId) => {
    const { practices, groupId } = this.state;

    POST.setPhaseStatus(status, groupId, practiceId, phaseId);

    practices.map(p => {
      if (p.id === practiceId) {
        p.phases.map(ph => {
          if (ph.id === phaseId) ph.status = status;
          return ph;
        });
      }
      return p;
    });

    this.setState({ practices });
  };

  updateResourceStatus = (status, practiceId, phaseId, resourceId) => {
    const { practices, groupId } = this.state;

    POST.setResourceStatus(status, groupId, practiceId, phaseId, resourceId);

    practices.map(p => {
      if (p.id === practiceId) {
        p.phases.map(ph => {
          if (ph.id === phaseId) {
            ph.resources.map(r => {
              if (r.id === resourceId) r.status = status;
              return r;
            });
          }
          return ph;
        });
      }
      return p;
    });

    this.setState({ practices });
  };

  updateCurrentPhase = (phaseName, practiceId) => {
    const { practices } = this.state;

    practices.map(p => {
      if (p.id === practiceId) p.currentPhase = phaseName;
      return p;
    });

    this.setState({ practices });
  };

  render() {
    const { children } = this.props;
    const { practices, token, email, user } = this.state;
    const value = {
      token,
      email,
      user,
      practices,
      saveCookies: this.saveCookies,
      removeCookies: this.removeCookies,
      loadTeamMembers: this.loadTeamMembers,
      loadPractices: this.loadPractices,
      loadAppData: this.loadAppData,
      getAppData: this.getAppData,
      getUser: this.getUser,
      setUser: this.setUser,
      updateUser: this.updateUser,
      updateTeamMember: this.updateTeamMember,
      deleteUser: this.deleteUser,
      addUsers: this.addUsers,
      getGroupId: this.getGroupId,
      getGroup: this.getGroup,
      updateGroup: this.updateGroup,
      getTeamMembers: this.getTeamMembers,
      updatePracticeStatus: this.updatePracticeStatus,
      updatePhaseStatus: this.updatePhaseStatus,
      updateResourceStatus: this.updateResourceStatus,
      updateCurrentPhase: this.updateCurrentPhase
    };

    return (
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    );
  }
}

export { Context, ContextProvider };
