import React from 'react';
import styled from 'styled-components';

import { Header } from '../components';


const Resources = ({ className }) => (
  <div className={className}>
    <Header title="Resources" />
  </div>
);


const StyledResources = styled(Resources)`

`;

export { StyledResources as Resources };
