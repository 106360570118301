import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Input, withData } from '../components';

import { POST } from '../../libs';


class LogInPwCreate extends React.Component {
  state = {
    password: '',
    passwordInvalid: false,
    passwordConfirm: '',
    passwordConfirmInvalid: false,
    loading: false,
    showConfirmation: false,
    showPwError: false,
    showKeyError: false
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLogInSubmit = e => {
    const { password, passwordConfirm } = this.state;
    const { showLoginForm, resetKey, resetLogin } = this.props;
    e.preventDefault();

    console.log(resetKey, resetLogin);

    if (!password) this.setState({ passwordInvalid: true });
    else this.setState({ passwordInvalid: false });

    if (!passwordConfirm) this.setState({ passwordConfirmInvalid: true });
    else this.setState({ passwordConfirmInvalid: false });

    if (password && passwordConfirm) {
      if (password !== passwordConfirm) {
        this.setState({ showPwError: true });
      } else {
        this.setState({ showPwError: false, loading: true });

        POST.resetPw(resetKey, resetLogin, password)
          .then(res => {
            this.setState({ loading: false });

            if (res.data !== true) {
              this.setState({ showKeyError: true });
              console.error(res.data);
            } else {
              showLoginForm(true);
            }
          })
          .catch(err => {
            this.setState({ loading: false });
            console.error(err);
          });
      }
    }
  };

  render() {
    const { className, showLoginForm, showResetPw } = this.props;
    const { password, passwordConfirm, passwordInvalid, passwordConfirmInvalid, loading, showPwError, showKeyError } = this.state;

    return (
      <form className={className} onSubmit={e => this.handleLogInSubmit(e)}>
        <p>Update your password.</p>
        {showPwError && (
          <div className="form_error">The passwords you entered do not match.</div>
        )}
        {showKeyError && (
          <div className="form_error">
            The passwords reset key is invalid. Please try again by clicking <span onClick={showResetPw}>here</span>.
          </div>
        )}
        <div className="form_row">
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={this.handleInputChange}
            errorMessage="Enter a valid password."
            shouldSetError={passwordInvalid}
            required
          />
        </div>
        <div className="form_row">
          <Input
            name="passwordConfirm"
            type="password"
            placeholder="Confirm Your Password"
            value={passwordConfirm}
            onChange={this.handleInputChange}
            errorMessage="Enter a valid password confirmation."
            shouldSetError={passwordConfirmInvalid}
            required
          />
        </div>
        <div className="form_row">
          <Input
            type="submit"
            name="submit"
            inputClassName="button primary shadow full_width"
            value="Reset Password"
            placeholder="Log In"
            disabled={loading}
          />
        </div>
        <div className="form_row">
          <span className="show_login" onClick={showLoginForm}>Back</span>
        </div>
      </form>
    );
  }
}

const StyledLogInPwCreate = styled(LogInPwCreate)`
  .form_error {
    margin: 0 0 20px;
    font-size: 14px;
    text-align: center;
    color: ${styles.error};

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .reset_password {
    position: absolute;
    right: 17px;
    top: 14px;
    z-index: 1;
    font-size: 12px;
    color: ${styles.primary};
    transition: color 50ms;
    cursor: pointer;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }

  .form_row {
    &.password {
      position: relative;

      input[type="password"] {
        padding-right: 80px;
      }
    }
  }

  .show_login {
    display: inline-block;
    margin-top: 20px;
    font-size: 12px;
    color: ${styles.primary};
    transition: color 50ms;
    cursor: pointer;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }
`;

const LogInPwCreateWithData = withData(StyledLogInPwCreate);
export { LogInPwCreateWithData as LogInPwCreate };
