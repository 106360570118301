import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';


const SideMenuNav = ({ className, getUser }) => (
  <ul className={className}>
    <li>
      <NavLink to="/" exact>Dashboard</NavLink>
    </li>
    <li>
      <NavLink to="/glossary" exact>Glossary</NavLink>
    </li>
    <li>
      <NavLink to="/participants">Participants</NavLink>
    </li>
    <li>
      <NavLink to="/team">My Team</NavLink>
    </li>
    <li>
      <NavLink to="/account">My Account</NavLink>
    </li>
  </ul>
);


const StyledSideMenuNav = styled(SideMenuNav)`
  list-style-type: none;
  margin: 60px 0 0;
  padding: 0 0 0 40px;

  li {
    padding: 15px 0;
  }

  a {
    letter-spacing: 0.01em;
    font-size: 20px;
    font-family: ${styles.nationalRegular};

    &.active {
      color: ${styles.primary};
    }
  }
`;

export { StyledSideMenuNav as SideMenuNav };
