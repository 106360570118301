import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Input, withData } from '../components';

import { POST } from '../../libs';


class LogInForm extends React.Component {
  state = {
    username: '',
    usernameInvalid: false,
    password: '',
    passwordInvalid: false,
    showError: false,
    loading: false
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLogInSubmit = e => {
    const { saveCookies, logIn } = this.props;
    const { username, password } = this.state;
    e.preventDefault();

    if (username && password) {
      this.setState({ loading: true });

      POST.logIn(username, password)
        .then(res => {
          const token = res.data.token;
          const email = res.data.user_email;

          this.setState({ loading: false }, () => {
            if (token && email) {
              saveCookies(token, email);
              // TODO: figure out how to wait until cookie is set in order to use token with next request //
              logIn(email);
            }
          });
        })
        .catch(err => {
          console.error(err.message);
          this.setState({ showError: true, loading: false });
        });
    }
  };

  render() {
    const { className, showResetPw, showResetConfirmation } = this.props;
    const { username, password, showError, loading } = this.state;

    return (
      <form className={className} onSubmit={e => this.handleLogInSubmit(e)} noValidate>
        {showResetConfirmation && <p>Your password has been updated. Please log in.</p>}
        {showError && (
          <div className="form_error">The email or password entered is invalid.</div>
        )}
        <div className="form_row">
          <Input
            name="username"
            placeholder="Your Email"
            value={username}
            onChange={this.handleInputChange}
            errorMessage="Enter a valid email."
            required
          />
        </div>
        <div className="form_row password">
          <Input
            type="password"
            name="password"
            placeholder="Your Password"
            value={password}
            onChange={this.handleInputChange}
            errorMessage="Enter a valid password."
            required
          />
        <span className="reset_password" onClick={showResetPw}>Forgot?</span>
        </div>
        <div className="form_row">
          <Input
            type="submit"
            name="submit"
            inputClassName="button primary shadow full_width"
            value="Login"
            placeholder="Log In"
            disabled={loading}
          />
        </div>
      </form>
    );
  }
}

const StyledLogInForm = styled(LogInForm)`
  .form_error {
    margin: -20px 0 20px;
    font-size: 14px;
    text-align: center;
    color: ${styles.error};
  }

  .reset_password {
    position: absolute;
    right: 17px;
    top: 14px;
    z-index: 1;
    font-size: 12px;
    color: ${styles.primary};
    transition: color 50ms;
    cursor: pointer;

    &:hover {
      color: ${styles.primaryHover};
      transition: color 300ms;
    }
  }

  .form_row {
    &.password {
      position: relative;

      input[type="password"] {
        padding-right: 80px;
      }
    }
  }
`;

const LogInFormWithData = withData(StyledLogInForm);
export { LogInFormWithData as LogInForm };
