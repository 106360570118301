import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const DropDownMenu = ({ className, children, backdrop, toggle, onListClick, fullHeight }) => (
  <span className={className}>
    <ul
      className={`dropdown_menu ${fullHeight ? 'full_height' : ''}`}
      onClick={onListClick || null}
    >
      {children}
    </ul>
    {backdrop && (<div className="backdrop" onClick={() => toggle && toggle()}></div>)}
  </span>
);

const StyledDropDownMenu = styled(DropDownMenu)`
  .dropdown_menu {
    position: absolute;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #fff;
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.12);
    min-width: 200px;
    z-index: 2;
    overflow-y: auto;

    &:not(.full_height) {
      max-height: 385px;
    }

    & > li {
      transition: background-color 250ms, color 250ms;

      &:hover {
        background-color: ${styles.primary};
        color: #fff;
      }
    }

    & > li {
      transition: background-color 250ms;

      & > a,
      & > span {
        white-space: nowrap;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 6px 20px;
        font-size: 14px;
        margin-right: 15px;
        font-weight: 400;
        cursor: pointer;
        transition: color 250ms;
      }

      &:hover {
        background-color: ${styles.secondary};

        & > a,
        & > span {
          color: #fff;
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: default;
  }
`;


export { StyledDropDownMenu as DropDownMenu };
