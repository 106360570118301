import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';


const BestPracticePageSocialShare = ({ className, practice }) => {
  const iconSize = 30;

  return (
    <div className={className}>
      <h3>Share this Best Practice</h3>
      <p className="details">{`Tell the world you have begun/completed ${practice.title}.`}</p>
      <ul>
        <li>
          <FacebookShareButton url={practice.url}>
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={practice.url}>
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={practice.url}>
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
        </li>
        {false && (
          <li>
            <PinterestShareButton url={practice.url} media={''}>
              <PinterestIcon size={iconSize} round />
            </PinterestShareButton>
          </li>
        )}
        <li>
          <RedditShareButton url={practice.url}>
            <RedditIcon size={iconSize} round />
          </RedditShareButton>
        </li>
        <li>
          <TumblrShareButton url={practice.url}>
            <TumblrIcon size={iconSize} round />
          </TumblrShareButton>
        </li>
        <li>
          <EmailShareButton url={practice.url}>
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </li>
      </ul>
    </div>
  );
};


const StyledBestPracticePageSocialShare = styled(BestPracticePageSocialShare)`
  margin-top: 60px;

  ul {
    text-align: center;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  .SocialMediaShareButton {
    cursor: pointer;
    outline: none;
  }

  .details {
    text-align: center;
    margin: -10px 0 25px !important;
    font-size: 14px;
    color: ${styles.textGrayed};
  }
`;

export { StyledBestPracticePageSocialShare as BestPracticePageSocialShare };
