import React from 'react';
import styled from 'styled-components';

import { Header } from '../components';


const Community = ({ className }) => (
  <div className={className}>
    <Header title="Community" />
  </div>
);


const StyledCommunity = styled(Community)`

`;

export { StyledCommunity as Community };
