import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const CheckBox = ({ className, label, value, name, checked = false, handleSelectAnswer }) => (
  <label className={`check_box ${className}`}>
    <div className="checkbox_container">
      <input type="checkbox" name={name} value={value} onChange={e => handleSelectAnswer(name, value)} checked={checked} />
      <i className="checkmark" />
    </div>
    <span>{label ? label : value}</span>
  </label>
);


const StyledCheckBox = styled(CheckBox)`
  cursor: pointer;

  .checkbox_container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 16px;
    height: 16px;

    input {
      width: 100%;
      height: 100%;
      appearance: none;
      border: 1px solid ${styles.formBorder};
      border-radius: 3px;
      vertical-align: middle;
      background-color: #fff;

      &:checked {
        background-color: ${styles.primary};
        border-color: ${styles.primary};

        & + .checkmark {
          display: block;
        }
      }
    }
  }

  input {
    cursor: pointer;
  }

  .checkmark {
    display: none;
    position: absolute;
    top: 8px;
    left: 2px;
    height: 5px;
    width: 11px;
    border: solid #fff;
    border-width: 0 0 2px 2px;
    transform: rotate(-40deg);
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
`;

export { StyledCheckBox as CheckBox };
