import axios from 'axios';
import { getCookie } from './cookies';

const API_BASE = `${process.env.REACT_APP_API_BASE}/wp-json/ss/v1`;
const TOKEN_API_BASE = `${process.env.REACT_APP_API_BASE}/wp-json/jwt-auth/v1/token`;

const GET = {
  user: email => {
    return axios.get(`${API_BASE}/users/email?email=${email}`);
  },

  practices: groupId => {
    const token = getCookie('auth_token');
    let url = `${API_BASE}/practices`;
    if (groupId) url += `?group_id=${groupId}`;
    return axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  practice: id => {
    const token = getCookie('auth_token');
    return axios.get(`${API_BASE}/practices/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  teamMembers: groupId => {
    const token = getCookie('auth_token');
    return axios.get(`${API_BASE}/groups/${groupId}/members`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  appData: () => {
    const token = getCookie('auth_token');
    return axios.get(`${API_BASE}/data`, { headers: { 'Authorization': `Bearer ${token}` } });
  }
};

const POST = {
  getSetupData: (group_uuid, user_email) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/setup`;
    return axios.post(url, { group_uuid, user_email }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  completeSetup: (group_id, why_joined) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/setup/complete`;
    return axios.post(url, { group_id, why_joined }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  logIn: (username, password) => {
    return axios.post(TOKEN_API_BASE, { username, password });
  },

  validateUserEmail: email => {
    const token = getCookie('auth_token');
    return axios.post(`${API_BASE}/users/validate`, { email }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  saveTeamMembers: (users, group_id) => {
    const token = getCookie('auth_token');
    return axios.post(`${API_BASE}/groups/${group_id}/users`, { users }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  sendPwResetEmail: email => {
    return axios.post(`${API_BASE}/users/pw-reset-email`, { email });
  },

  resetPw: (key, email, password) => {
    return axios.post(`${API_BASE}/users/pw-reset`, { key, email, password });
  },

  updatePw: (userId, password) => {
    return axios.post(`${API_BASE}/users/${userId}/pw-update`, { password });
  },

  checkPassword: (userId, password) => {
    return axios.post(`${API_BASE}/users/${userId}/pw-check`, { password });
  },

  setResourceStatus: (status, group_id, practiceId, phaseId, resourceId) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/practices/${practiceId}/phases/${phaseId}/resources/${resourceId}`;
    return axios.post(url, { group_id, status }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  setPhaseStatus: (status, group_id, practiceId, phaseId) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/practices/${practiceId}/phases/${phaseId}`;
    return axios.post(url, { group_id, status }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  setPracticeStatus: (status, group_id, practiceId) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/practices/${practiceId}`;
    return axios.post(url, { group_id, status }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  submitSurvey: (group_id, practiceId, phaseId, resourceId, questions, type) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/practices/${practiceId}/phases/${phaseId}/resources/${resourceId}/survey`;
    return axios.post(url, { group_id, questions, type }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  submitFeedback: (group_id, practiceId, phaseId, resourceId, rating, feedback) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/practices/${practiceId}/phases/${phaseId}/resources/${resourceId}/feedback`;
    return axios.post(url, { group_id, rating, feedback }, { headers: { 'Authorization': `Bearer ${token}` } });
  }
};

const PUT = {
  updateUser: (data, userId) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/users/${userId}`;
    return axios.put(url, { data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  updateTooltipStatus: (data, userId) => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/users/${userId}/tooltip`;
    return axios.put(url, { data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  updateGroup: data => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/groups/${data.id}`;
    return axios.put(url, { data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },
}

const DELETE = {
  user: userId => {
    const token = getCookie('auth_token');
    const url = `${API_BASE}/users/${userId}`;
    return axios.delete(url, { headers: { 'Authorization': `Bearer ${token}` } });
  }
};

export {
  GET,
  POST,
  PUT,
  DELETE
};
