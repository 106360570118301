import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../../styles';

import {
  Logo,
  Loader,
  SetupCard,
  withData
} from '../components';

import { POST } from '../../libs';


class Setup extends React.Component {
  state = {
    loading: true,
    groupUuid: null,
    groupId: null,
    firstName: ''
  };

  setUser = data => {
    const user = {
      id: parseInt(data.id),
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.group_role
    };
    const groupId = parseInt(data.group_id);

    this.setState({ user, groupId });
  };

  componentDidMount() {
    const { location, match, history, setUser, saveCookies } = this.props;
    const groupUuid = match.params.group_uuid;
    let userEmail, token;

    if (location.state) {
      userEmail = location.state.email;
      token = location.state.token;
      location.state.wizard !== 'true' && saveCookies(token, userEmail);
    }
    // const userEmail = getCookie('auth_email');

    console.log('groupUuid', groupUuid);
    console.log('userEmail', userEmail);

    this.setState({ groupUuid }, () => {
      POST.getSetupData(groupUuid, userEmail)
        .then(res => {
          const { group_id, user } = res.data;

          console.log('user', user, group_id);
          console.log('history.push(/)', user.group_role !== 'admin' || user.group_id !== group_id);

          if (user.group_role !== 'admin' || user.group_id !== group_id) {
            history.push('/');
          } else {
            setUser(user);
            this.setState({ loading: false, groupId: group_id, firstName: user.first_name });
          }
        });
    });
  }

  render() {
    const { className } = this.props;
    const { loading, firstName, groupId } = this.state;

    return (
      <div className={className}>
        {loading ? (
          <div className="logo_container">
            <Logo />
            <Loader
              className="loading"
              size={23}
            />
          </div>
        ) : (
          <Fragment>
            <Logo className="title" />
            <div className="content_container">
              <h1>Welcome {firstName}, we're so glad you've joined <br />the Aleinu campaign.</h1>
              <h2>Let's get started with a quick setup.</h2>
              <SetupCard
                groupId={groupId}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}


const StyledSetup = styled(Setup)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow: auto;
  padding-bottom: 100px;
  background-color: ${styles.pageBg};

  .logo.title {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .content_container {
    margin: 8% auto 0;
    text-align: center;
    width: 100%;
    max-width: 1000px;

    @media (max-width: 1440px) {
      margin: 115px auto 0;
    }

    h1 {
      font-size: 38px;
      color: ${styles.heading};
      font-weight: normal;
      letter-spacing: 0.01em;
      line-height: 1.5;
      padding: 0 40px;
      margin: 20px 0 30px;
    }

    h2 {
      margin: 0;
      font-size: 20px;
      color: ${styles.secondary};
      font-weight: normal;
    }
  }

  .logo_container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .logo {
      width: 260px;
      margin-bottom: 5px;
    }

    .loader {
      opacity: 0;

      &.loading {
        opacity: 1;
      }
    }
  }
`;

const SetupWithRouter = withRouter(StyledSetup);
const SetupWithData = withData(SetupWithRouter);
export { SetupWithData as Setup };
