import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';
import icons from './Icons';

const Icon = ({ className, name, iconStyle = '', color }) => (
  <div className={`icon ${className} ${iconStyle} ${color ? 'colored' : ''}`}>
    {icons[name] && icons[name]()}
  </div>
);

const StyledIcon = styled(Icon)`
  svg {
    display: block;
    height: 100%;
    margin: auto;
  }

  .line {
    fill: ${styles.logoBlue};
    transition: fill 300ms;
  }

  .stroke {
    stroke: ${styles.logoBlue};
    transition: stroke 300ms;
  }

  &.primary {
    .line {
      fill: ${styles.primary};
    }

    .stroke {
      stroke: ${styles.primary};
    }
  }

  &.secondary {
    .line {
      fill: ${styles.secondary};
    }

    .stroke {
      stroke: ${styles.secondary};
    }
  }

  &.blue {
    .line {
      fill: ${styles.blue};
    }

    .stroke {
      stroke: ${styles.blue};
    }
  }

  &.grayed {
    .line {
      fill: ${styles.grayed};
    }

    .stroke {
      stroke: ${styles.grayed};
    }
  }

  &.white {
    .line {
      fill: #fff;
    }

    .stroke {
      stroke: #fff;
    }
  }

  &.colored {
    .line {
      fill: ${props => props.color};
    }

    .stroke {
      stroke: ${props => props.color};
    }
  }

  .light {
    opacity: 0.4;
  }

  .stroke {
    stroke-miterlimit: 5;
    stroke-width: 5px;

    &.thick {
      stroke-miterlimit: 10;
      stroke-width: 10px;
    }
  }
`;

export { StyledIcon as Icon };
