import React from 'react';
import styled from 'styled-components';

const BestPracticePageMoreResources = ({ className }) => (
  <div className={className}>
    <h3>More Resources</h3>
  </div>
);

const StyledBestPracticePageMoreResources = styled(BestPracticePageMoreResources)`
  margin-top: 60px;
`;

export { StyledBestPracticePageMoreResources as BestPracticePageMoreResources };
