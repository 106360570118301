import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Header, GlossaryTerm } from '../components';


class Glossary extends React.Component {
  state = {
    introduction: '',
    terms: []
  };

  componentDidMount() {
    const { getAppData } = this.props;
    const { glossary: { introduction, terms } } = getAppData();
    this.setState({ introduction, terms });
  }

  render() {
    const { className } = this.props;
    const { introduction, terms } = this.state;

    return (
      <div className={className}>
        <Header title="Glossary" />
        {introduction !== '' && (
          <div className="intro" dangerouslySetInnerHTML={{ __html: introduction }} />
        )}

        <ul className="terms">
          {terms.length > 1 && terms.map((t, i) => (<GlossaryTerm key={`${t.term}_${i}`} term={t} />))}
        </ul>
      </div>
    );
  }
}


const StyledGlossary = styled(Glossary)`
  .intro {
    border-bottom: 1px solid ${styles.borderDark};
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .terms {
    padding: 0;
    list-style-type: none;
  }
`;

export { StyledGlossary as Glossary };
