import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { SectionTitle, Mailto } from '../components';

const TeamMembersTable = ({ className, teamMembers, user, isAdmin, handleOpenEditMember, handleOpenDeleteMember }) => (
  <div className={className}>
    <SectionTitle title="My Team" />
    <table className="data_table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Title</th>
          <th>Email</th>
          {isAdmin && (
            <Fragment>
              <th>Role</th>
              <th />
            </Fragment>
          )}
        </tr>
      </thead>
      <tbody>
        {teamMembers && teamMembers.map((member, i) => (
          <tr key={`${member.id}_${i}`}>
            <td>{`${member.first_name} ${member.last_name}`}</td>
            <td>{member.title}</td>
            <td>
              <Mailto email={member.email}>{member.email}</Mailto>
            </td>
            {isAdmin && (
              <Fragment>
                <td>{member.group_role === 'member' ? 'team member' : member.group_role}</td>
                <td className="action_buttons">
                  {user.id !== member.id && (
                    <Fragment>
                      <span className="action_button edit" onClick={() => handleOpenEditMember(member)}>
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                      <span className="action_button delete" onClick={() => handleOpenDeleteMember(member)}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </span>
                    </Fragment>
                  )}
                </td>
              </Fragment>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const StyledTeamMembersTable = styled(TeamMembersTable)`
  margin-top: 35px;

  .action_buttons {
    text-align: right;
  }

  .action_button {
    font-size: 18px;
    cursor: pointer;
    transition: color 50ms;

    &:hover {
      transition: color 300ms;
    }

    &.edit {
      color: ${styles.primary};

      &:hover {
        color: ${styles.primaryHover};
      }
    }

    &.delete {
      color: ${styles.primary};
      margin: 0 10px 0 20px;
      // margin-left: 20px;

      &:hover {
        color: ${styles.error};
      }
    }
  }
`;

export { StyledTeamMembersTable as TeamMembersTable };
