import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';


const Tooltip = ({ className, show, content }) => (
  <div className={`tooltip ${className} ${show ? 'show' : ''}`}>
    {content}
  </div>
);


const StyledTooltip = styled(Tooltip)`
  position: absolute;
  opacity: 0;
  font-size: 14px;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  line-height: 1.2;
  padding: 8px 10px 10px;
  border-radius: 3px;
  white-space: nowrap;
  transition: opacity 50ms;
  text-transform: none;
  letter-spacing: normal;
  font-family: ${styles.nationalBook};

  &::after {
    content: '';
    border: 6px solid;
    border-color: rgba(0,0,0,0.6) transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
  }

  &.show {
    opacity: 1;
  }
`;

export { StyledTooltip as Tooltip };
