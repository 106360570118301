import React from 'react';
import styled from 'styled-components';
import styles from '../../styles';
import imagesLoaded from 'imagesloaded';

import { Header, Logo } from '../components';

import { dom, sortBy } from '../../libs';


class Participants extends React.Component {
  state = {
    introduction: '',
    participants: [],
    showCards: false,
    cardHeight: null
  };

  componentDidMount() {
    const { getAppData } = this.props;
    const { groups: participants } = getAppData();
    this.setState({ participants: sortBy(participants, 'name') }, () => {
      imagesLoaded(document.querySelectorAll('.participants_container li'), () => this.setCardHeights());
    });

    window.addEventListener('resize', this.resetResizeValues);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetResizeValues);
  }

  setCardHeights = () => {
    const cardHeight = dom.getStandardizedHeight('.participants_container li');
    this.setState({ cardHeight, showCards: true });
  };

  resetResizeValues = () => {
    this.setState({ cardHeight: null }, () => this.setCardHeights());
  };

  formatUrl = url => {
    return 'http://' + url.replace(/https:\/\/|http:\/\//g, '');
  };

  getOrganizationType = p => {
    let types = p.organization_type.split(',');

    if (types.includes('Other') && p.organization_type_other) {
      types = types.filter(t => t !== 'Other').concat([p.organization_type_other]);
    }

    return types.join(', ');
  };

  render() {
    const { className } = this.props;
    const { introduction, participants, showCards, cardHeight } = this.state;
    const introClasses = ['intro'];

    if (introduction !== '') introClasses.push('has_intro');

    return (
      <div className={className}>
        <Header title="Participants" />
        <div className={introClasses.join(' ')} dangerouslySetInnerHTML={{ __html: introduction }} />
        <ul className="participants_container">
          {participants && participants.map((p, i) => {
            if (p.is_test === '1') return null;

            return (
              <li
                key={p.id}
                style={{
                  height: cardHeight,
                  opacity: showCards ? 1 : 0
                }}
              >
                {p.organization_logo
                  ? <img src={p.organization_logo} className="logo" alt={p.name} />
                : <Logo logoStyle="placeholder" />
                }
                <h2>{p.name}</h2>
                <p className="location"><i className="fa fa-globe" aria-hidden="true"></i> {p.city}, {p.state}</p>
                <p className="type"><i className="fa fa-users" aria-hidden="true"></i> {this.getOrganizationType(p)}</p>
                {p.admins.length > 0 && (
                  <p className="admin">
                    <i className="fa fa-envelope" aria-hidden="true" />
                    {p.admins.map(a => <a href={`mailto:${a.email}`} key={a.email}>{a.name}</a>)}
                  </p>
                )}
                {p.website && (
                  <p className="website">
                    <i className="fa fa-desktop" aria-hidden="true"></i>
                    <a href={this.formatUrl(p.website)} target="_blank" rel="noopener noreferrer">{p.website}</a>
                  </p>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}


const StyledParticipants = styled(Participants)`
  .intro {
    border-bottom: 1px solid ${styles.borderDark};
    margin-bottom: 30px;

    &.introduction {
      padding-bottom: 30px;
    }
  }

  h2 {
    font-family: ${styles.nationalBold};
    font-size: 16px;
    color: ${styles.heading};
    margin: 15px 0;
    text-align: center;
  }

  .logo {
    max-height: 65px;
    width: auto !important;
    max-width: 100%;
    display: block;
    margin: 5px auto 25px;
  }

  li {
    display: inline-block;
    background-color: #fff;
    margin: 10px;
    width: 280px;
    vertical-align: top;
    border: 1px solid ${styles.textLight};
    padding: 25px 25px 17px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px 0px rgba(45, 47, 56, 0.05);

    p {
      margin: 0;
      position: relative;
      padding-left: 30px;
      line-height: 1.3;
      margin-bottom: 8px;

      &.website {
        word-wrap: break-word;
      }

      &:not(.website) {
        text-transform: capitalize;
      }

      .fa {
        position: absolute;
        left: 0;
        font-size: 13px;
        opacity: 0.5;
        top: 5px;

        &.fa-globe {
          top: 4px;
          left: 2px;
          font-size: 14px;
        }
      }
    }
  }
`;

export { StyledParticipants as Participants };
