import React from 'react';

import {
  AccountUpdateFormDetails,
  Button,
  ModalDialog,
  withData
} from '../components';

import { POST, validate } from '../../libs';

const defaultValidFields = {
  firstName: true,
  lastName: true,
  email: true,
  phone: true
};

const defaultEmailErrorMessage = 'Enter a valid email.';

const defaultValidation = {
  validFields: defaultValidFields,
  emailErrorMessage: defaultEmailErrorMessage
};


class AccountUpdateForm extends React.Component {
  state = {
    email: '',
    first_name: '',
    last_name: '',
    group_name: '',
    title: '',
    phone: '',
    loading: false,
    showConfirmationModal: false,
    ...defaultValidation
  };

  componentDidMount() {
    const { getUser } = this.props;
    const userData = getUser();
    this.setState(userData, () => this.formIsValid());
  }

  toggleConfirmationModal = () => {
    this.setState(prevState => ({ showConfirmationModal: !prevState.showConfirmationModal }));
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleUpdateAccount = () => {
    const { getUser } = this.props;
    const user = getUser();
    const userData = Object.assign({}, this.state);
    const validFields = Object.assign({}, this.state.validFields);

    this.setState({ ...defaultValidation, loading: true });

    if (user.email === userData.email) {
      delete userData.email;
      this.validateAndUpdateUser(userData);
    } else {
      POST.validateUserEmail(userData.email)
        .then(({ data: uniqueEmail }) => {
          if (uniqueEmail) {
            this.validateAndUpdateUser(userData);
          } else {
            validFields.email = false;
            this.setState({ validFields, emailErrorMessage: 'This email is already in use.', loading: false });
          }
        });
    }
  };

  validateAndUpdateUser = userData => {
    const { updateUser } = this.props;

    const data = {
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      title: userData.title,
      phone: userData.phone
    };

    if (userData.email) data.email = userData.email;

    if (this.formIsValid()) {
      updateUser(data).then(res => this.setState({ loading: false, showConfirmationModal: true }));
    } else {
      console.log('invalid');
      this.setState({ loading: false });
    }
  };

  formIsValid = () => {
    const { email, first_name, last_name, phone } = this.state;
    const validFields = {
      ...defaultValidFields,
      firstName: first_name !== '',
      lastName: last_name !== '',
      phone: phone !== ''
    };

    if (!validate.email(email)) {
      validFields.email = false;
      this.setState({ emailErrorMessage: defaultEmailErrorMessage });
    }

    this.setState({ validFields });
    return Object.values(validFields).every(v => v);;
  };

  handleInputValidate = (name, value) => {
    if (this.state.validFields[name] !== value) {
      this.setState(prevState => ({
        validFields: {
          ...prevState.validFields,
          [name]: value
        }
      }));
    }
  };

  render() {
    const { loading, validFields, emailErrorMessage, showConfirmationModal } = this.state;
    const { getUser } = this.props;
    const user = getUser();
    const isValid = Object.values(validFields).every(f => f);

    return (
      <div>
        <form noValidate className="manage_form">
          <AccountUpdateFormDetails
            data={this.state}
            isAdmin={user.role === 'admin'}
            onInputChange={this.handleInputChange}
            onValidate={this.handleInputValidate}
            valid={validFields}
            emailErrorMessage={emailErrorMessage}
          />
          <div className="button_container">
            <Button
              handleClick={this.handleUpdateAccount}
              loading={loading}
              disabled={loading || !isValid}
              shadow
            >
              Update Account
            </Button>
          </div>
        </form>
        <ModalDialog
          show={showConfirmationModal}
          toggle={this.toggleConfirmationModal}
          text="Your account has been updated."
        />
      </div>
    );
  }
};


const AccountUpdateFormWithData = withData(AccountUpdateForm);
export { AccountUpdateFormWithData as AccountUpdateForm };
