import React from 'react';
import styled from 'styled-components';

class BestPracticesPageVideo extends React.Component {
  state = {
    showOverlay: false,
    showVideo: false
  };

  handleStopPropagation = e => e.stopPropagation();

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showOverlay: true });
    }, 50);
    setTimeout(() => {
      this.setState({ showVideo: true });
    }, 250);
  }

  handleHideOverlay = () => {
    const { handleCloseVideo } = this.props;

    this.setState({ showOverlay: false, showVideo: false }, () => {
      setTimeout(handleCloseVideo, 300);
    });
  };

  render() {
    const { className, videoUrl, videoType } = this.props;
    const { showOverlay, showVideo } = this.state;
    const embedVideo = videoType === 'embeddable';

    return (
      <div
        className={`${className} ${showOverlay ? 'show' : ''}`}
        onClick={this.handleHideOverlay}
      >
      {showVideo && (
        <div 
          className={`video_modal${embedVideo ? ' embed' : ''}`} 
          onClick={e => this.handleStopPropagation(e)}
        >
          <i className="closer" onClick={this.handleHideOverlay} />
          {embedVideo ? (
            <iframe 
              src={videoUrl}
              frameBorder="0" 
              allowFullScreen
              title="Aleinu Campaign Resources"
            />
          ) : (
            <video controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
          )}
        </div>
      )}
      </div>
    );
  }
}

const StyledBestPracticesPageVideo = styled(BestPracticesPageVideo)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 20000;
  opacity: 0;
  transition: opacity 300ms;

  &.show {
    opacity: 1;
  }

  .video_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 840px;
    padding: 20px;

    video {
      width: 100%;

      &:focus {
        outline: none;
      }
    }
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .closer {
      top: -3px;
      right: -15px;
      transition: opacity 50ms;
      cursor: pointer;

      &::before,
      &::after {
        background-color: #fff;
      }

      &:hover {
        opacity: 0.8;
        transition: opacity 300ms;
      }
    }
    
    &.embed {
      &::before {
        content: '';
        display: block;
        padding-top: 55%;
      }
      
      .closer {
        top: -23px;
        right: -38px;
      }
    }
  }
`;

export { StyledBestPracticesPageVideo as BestPracticesPageVideo };
