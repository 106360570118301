import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import styles from '../../styles';

import { Loader, Tooltip } from '../components';


const Button = props => {
  const {
    className,
    children,
    handleClick,
    buttonStyle = 'primary',
    size = '',
    shadow = '',
    link,
    disabled,
    loading,
    loaderPos = 'right',
    tooltip = false
  } = props;

  const [isHover, setHover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const classList = [
    'button',
    className,
    buttonStyle,
    size,
    shadow,
    disabled ? 'disabled' : '',
    loading ? 'loading' : '',
    `loader_${loaderPos}`
  ];

  const handleOnClickComponent = e => {
    if (!disabled) handleClick(e);
  };

  const handleOnClickLink = e => {
    if (disabled) e.preventDefault();
  };

  const handleIsHover = shouldHover => {
    if (shouldHover) {
      setHover(true);
      setTimeout(() => setShowTooltip(true), 10);
    } else {
      setShowTooltip(false);
      setTimeout(() => setHover(false), 200);
    }
  };

  if (link) {
    return (
      <a
        className={classList.join(' ')}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onMouseOver={() => handleIsHover(true)}
        onMouseLeave={() => handleIsHover(false)}
        onClick={handleOnClickLink}
      >
        {tooltip && isHover && <Tooltip show={showTooltip} content={tooltip} />}
        {children}
      </a>
    );
  } else {
    return (
      <Fragment>
        {loading && loaderPos === 'left' && <Loader />}
        <span
          className={classList.join(' ')}
          onClick={handleOnClickComponent}
          onMouseOver={() => handleIsHover(true)}
          onMouseLeave={() => handleIsHover(false)}
        >
          {tooltip && isHover && <Tooltip show={showTooltip} content={tooltip} />}
          {children}
        </span>
        {loading && loaderPos !== 'left' && <Loader />}
      </Fragment>
    );
  }
};

const StyledButton = styled(Button)`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  padding: 13px 15px 15px;
  border-radius: 3px;
  border: 1px solid;
  text-transform: uppercase;
  font-size: 12.5px;
  font-family: ${styles.nationalBold};
  letter-spacing: 0.18em;
  cursor: pointer;
  line-height: 1;
  transition: background-color 50ms, border-color 50ms, color 50ms;

  &:hover {
    transition: background-color 300ms, border-color 300ms, color 300ms;
  }

  &.sm {
    padding: 9px 13px 11px;
  }

  &.primary {
    background-color: ${styles.primary};
    border-color: ${styles.primary};

    &:hover {
      background-color: ${styles.primaryHover};
      border-color: ${styles.primaryHover};
    }

    &.disabled {
      background-color: ${styles.primaryDisabled};
      border-color: ${styles.primaryDisabled};
    }
  }

  &.secondary {
    background-color: ${styles.secondary};
    border-color: ${styles.secondary};

    &:hover {
      background-color: ${styles.secondaryHover};
      border-color: ${styles.secondaryHover};
    }
  }

  &.destroy {
    background-color: ${styles.error};
    border-color: ${styles.error};

    &:hover {
      background-color: ${styles.errorHover};
      border-color: ${styles.errorHover};
    }
  }

  &.outline {
    background-color: #fff !important;

    &.primary {
      color: ${styles.primary};

      &:hover {
        color: ${styles.primaryHover};
      }
    }

    &.secondary {
      color: ${styles.secondary};

      &:hover {
        color: ${styles.secondaryHover};
      }
    }
  }

  &.simple {
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  &.shadow {
    box-shadow: 0px 8px 20px 0px rgba(45, 47, 56, 0.19);
  }

  &.disabled {
    cursor: default;
  }

  &.loader_left {
    margin-left: 15px;
  }

  & + .loader {
    margin-left: 15px;
  }

  i.fa {
    display: inline-block;
    margin-right: 5px;
  }
`;

export { StyledButton as Button };
