import React, { Fragment } from 'react';

import {
  Header,
  DashboardProgress,
  DashboardResources,
  withData
} from '../components';


const Dashboard = ({ getUser }) => {
  const user = getUser();

  return (
    <div>
      <Header title={`Welcome, ${user.first_name}!`} />
      <DashboardProgress />
      {false && (
        <Fragment>
          <hr />
          <DashboardResources />
        </Fragment>
      )}
    </div>
  );
}


const DashboardWithData = withData(Dashboard);
export { DashboardWithData as Dashboard };
